<template>
  <div>
    <jTitle :title="config.title" />
    <span class="dashed-btn" v-if="isEdit" @click="handerShowChildDevice">+关联{{ config.btnNm }}</span>
    <jTable v-model="config.tableConfig" :tableData="filterDevices" :total="deviceTotal" v-if="selectList.length"
      ref="jTable" @search="search" :controlWidth="'80'" height="350px">
      <template v-slot:control="scope" v-if="isEdit">
        <span class="control" @click="handlerDel(scope.row, 'device')">删除</span>
      </template>
    </jTable>
    <jSelectTable v-model="showChildDevice" v-if="!config.isRadio" :title="'选择' + config.title" :span="config.span"
      :baseForm="config.searchConfig" :columnConfig="config.tableConfig" :list="selectList" width="950px"
      @submit="setDeviceList" :listUrl="config.url" :params="config.params" />
    <jRadioTable v-model="showChildDevice" v-else :mainInfo="setMainInfo" :showSearch="config.showSearch" width="950px"
      :baseForm="config.searchConfig" :title="config.title" :columnConfig="config.tableConfig" @submit="setDeviceList"
      :listUrl="config.url" :params="config.params" />
  </div>
</template>
<script>
export default {
  name: 'checkboxTable',
  components: {

  },
  props: {
    value: [Object, Array],
    config: {
      type: Object,
      default: () => { }
    },
    isEdit: {
      type: Boolean,
      default: true
    },
  },
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.selectList = val
        } else {
          this.selectList = []
        }
      },
      immediate: true,
    },
  },
  computed: {
    filterDevices() {
      this.deviceTotal = this.selectList.length || 0
      let selectList = this.selectList.slice(this.pager.pageSize * (this.pager.pageNum * 1 - 1), this.pager.pageSize * this.pager.pageNum)
      if (selectList.length == 0 && this.pager.pageNum != 1) {
        this.pager.pageNum -= 1
        selectList = this.selectList.slice(this.pager.pageSize * (this.pager.pageNum * 1 - 1), this.pager.pageSize * this.pager.pageNum)
      }
      return selectList
    },
    setMainInfo() {
      if (Array.isArray(this.selectList)) {
        let [mainInfo] = this.selectList
        return mainInfo
      }
      return this.selectList
    }
  },
  data() {
    return {
      selectList: [],
      deviceTotal: 0,
      showChildDevice: false,
      pager: {
        pageNum: 1,
        pageSize: 50,
      },
    }
  },
  methods: {
    handerShowChildDevice() {
      this.showChildDevice = true
      this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
    },
    setDeviceList(checkInfo) {
      this.showChildDevice = false
      this.selectList = this.config.isRadio ? [checkInfo] : checkInfo
      this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
      this.$emit('input', JSON.parse(JSON.stringify(this.selectList)))
    },
    search(formData) {
      this.pager.pageNum = formData.pageNum
      this.pager.pageSize = formData.pageSize
    },
    handlerDel(rowData, type) {
      if (type == 'device') {
        this.selectList = this.selectList.filter(item => item.id != rowData.id)
        this.$emit('input', this.selectList)
      }
    },
  }
}
</script>
<style scoped lang="scss">
.j-form-bottom {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.dashed-btn {
  display: block;
  width: 100%;
  padding: 5px;
  border: 1px dashed #409EFF;
  color: #409EFF;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}
</style>
