<template>
  <el-form ref="form" :class="{ 'flex-c': center }" :label-position="position" :model="formData" class="app-search-form"
    label-width="auto">
    <el-divider v-if="pageName" content-position="left"><span>
        {{ pageName }}
      </span></el-divider>
    <el-row :gutter="24" :style="{ width: formWidth }" class="flex-start-row">
      <el-col v-for="(configItem, i) in baseForm"
        v-if="(!configItem.showByView || (configItem.showByView && !isEdit)) && !configItem.hide" :key="i"
        :span="['title'].includes(configItem.type) ? 24 : (configItem.span || 8)">
        <!-- -- title -- -->
        <el-form-item v-if="configItem.type === 'title'" class="app-search-item" style="margin-bottom: 0;"
          label-width="0px">
          <jTitle :title="configItem.title" />
        </el-form-item>
        <!-- -- input -- -->
        <el-form-item v-else-if="['input', 'password'].includes(configItem.type)" :label="configItem.label"
          :prop="configItem.prop"
          :rules="isEdit ? (configItem.valiFun ? setRules(configItem.rules, configItem.valiFun, configItem.valiprop) : configItem.rules) || null : null"
          class="app-search-item">
          <el-input v-if="!configItem.prop1" v-model.trim="formData[configItem.prop]"
            :disabled="configItem.disabled || !isEdit" :maxlength="configItem.maxlength ? configItem.maxlength : 100000"
            :placeholder="configItem.placeholder" :show-password="configItem.type === 'password'"
            :show-word-limit="configItem.maxlength > 0" clearable style="width:100%" />
          <el-input v-else :placeholder="configItem.placeholder"
            :value="formData[configItem.prop1] ? [formData[configItem.prop1] || '', formData[configItem.prop] || ''].join('_') : formData[configItem.prop]"
            disabled style="width:100%" />
        </el-form-item>
        <!-- -- inputs -- -->
        <div v-else-if="configItem.type === 'inputs'">
          <el-form-item :label="configItem.label" :prop="configItem.prop"
            :rules="isEdit ? configItem.rules || null : null" class="app-search-item selects">
            <div class="flex-start-row">
              <el-input v-for="(cItem, idx) in configItem.list" :key="idx" v-model="formData[cItem.prop]"
                :disabled="cItem.disabled || !isEdit" :placeholder="cItem.placeholder" clearable
                style="margin-right: 5px;">
              </el-input>
            </div>
          </el-form-item>
        </div>
        <!-- -- textarea -- -->
        <el-form-item v-else-if="configItem.type === 'textarea'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <el-input v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
            :maxlength="configItem.maxlength ? configItem.maxlength : 100000" :placeholder="configItem.placeholder"
            :show-word-limit="configItem.maxlength > 0" style="width:100%" type="textarea" />
        </el-form-item>
        <!-- -- number -- -->
        <el-form-item v-else-if="configItem.type === 'number'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <el-input-number v-model="formData[configItem.prop]" :controls="false"
            :disabled="configItem.disabled || !isEdit" :max="99999" :min="0" :step="1" step-strictly></el-input-number>
        </el-form-item>
        <!-- -- radio -- -->
        <el-form-item v-else-if="configItem.type === 'radio'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <template>
            <el-radio-group v-model="formData[configItem.prop]" size="medium">
              <el-radio v-for="(item, i) in baseOption[configItem.prop]" :key="i"
                :disabled="configItem.disabled || !isEdit" :label="item.value" border>{{
                  item.label
                }}
              </el-radio>
            </el-radio-group>
          </template>
        </el-form-item>
        <!-- -- checkbox -- -->
        <el-form-item v-else-if="configItem.type === 'checkbox'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <template>
            <el-checkbox-group v-model="formData[configItem.prop]">
              <el-checkbox v-for="(item, i) in baseOption[configItem.prop]" :key="i"
                :disabled="configItem.disabled || !isEdit" :label="item.value" border>{{
                  item.label
                }}
              </el-checkbox>
            </el-checkbox-group>
          </template>
        </el-form-item>
        <!-- -- tree -- -->
        <el-form-item v-else-if="configItem.type === 'button'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <template>
            <el-button :disabled="configItem.disabled || !isEdit" class="button-origin"
              style="width:100%;text-align:left" @click="selectOrgId">{{
                formData.placeholder
              }}
            </el-button>
          </template>
        </el-form-item>
        <!-- -- tree -- -->
        <el-form-item v-else-if="configItem.type === 'tree'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <template>
            <jdTree v-model="formData[configItem.prop]"
              :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }"
              :disabled="configItem.disabled || !isEdit" treeType="id" />
          </template>
        </el-form-item>
        <!-- -- tree -- -->
        <el-form-item v-else-if="configItem.type === 'jSelectTree'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <template>
            <jSelectTree v-model="formData[configItem.prop]" :dataList="baseOption[configItem.prop]"
              :defaultProps="{ children: 'childOrgVos', label: 'orgName', value: 'orgNum' }"
              :disabled="configItem.disabled || !isEdit" />
          </template>
        </el-form-item>
        <!-- -- tree -- -->
        <el-form-item v-else-if="configItem.type === 'jMenuTree'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <template>
            <jMenuTree v-model="formData[configItem.prop]" :dataList="baseOption[configItem.prop]"
              :defaultProps="{ children: 'subMenus', label: 'name', value: 'id' }"
              :disabled="configItem.disabled || !isEdit" />
          </template>
        </el-form-item>
        <!-- -- select -- -->
        <el-form-item v-else-if="configItem.type === 'select'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <el-select v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
            :filterable="configItem.filterable || true" :multiple="Array.isArray(formData[configItem.prop]) || false"
            :placeholder="configItem.placeholder" clearable @change="eventFun($event, configItem)">
            <el-option v-for="item in (configItem.options || baseOption[configItem.prop])" :key="item.value"
              :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- selects -->
        <div v-else-if="configItem.type === 'selects'">
          <el-form-item v-for="(item, i) in formData[configItem.prop]" :key="i" :label="configItem.label"
            :prop="configItem.prop + '.' + i" :rules="isEdit ? configItem.rules || null : null"
            class="app-search-item selects">
            <div class="flex-start-row">
              <el-select v-for="(cItem, idx) in configItem.list" :key="idx" v-model="formData[cItem.prop][i]"
                :disabled="cItem.disabled || !isEdit" :filterable="cItem.filterable || true"
                :multiple="Array.isArray(formData[cItem.prop][i]) || false" :placeholder="cItem.placeholder" clearable
                style="margin-right: 5px;" @change="cItem.change ? eventFun($event, cItem) : null">
                <el-option v-for="item in (configItem.options || baseOption[cItem.prop])" :key="item.value"
                  :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <span v-if="isEdit" class="el-icon-circle-plus-outline" @click="addList(configItem)"></span>
              <span v-if="isEdit && i != 0" class="el-icon-remove-outline" @click="delList(configItem, i)"></span>
            </div>
          </el-form-item>
        </div>
        <div v-else-if="configItem.type === 'area'">
          <el-form-item :label="configItem.label"
            :rules="isEdit ? [{ required: configItem.required, validator: validArea, trigger: 'change' }] || null : null"
            class="app-search-item" label-width="80px" prop="provinceCode,cityCode,countyCode">
            <div class="flex-row">
              <el-select v-model="formData.provinceCode" :disabled="configItem.disabled || !isEdit" filterable
                placeholder="省份" @change="($event) => {
                  getSubOption($event, 'cityCode')
                  formData.provinceName = setLabelByName($event, baseOption.provinceCode)
                  formData.cityCode = undefined
                  formData.countyCode = undefined
                }">
                <el-option v-for="item in baseOption.provinceCode" :key="item.id" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
              <el-select v-model="formData.cityCode" :disabled="configItem.disabled || !isEdit" class="mg-5"
                placeholder="市" @change="($event) => {
                  getSubOption($event, 'countyCode')
                  formData.cityName = setLabelByName($event, baseOption.cityCode)
                  formData.countyCode = undefined
                }">
                <el-option v-for="item in baseOption.cityCode" :key="item.id" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
              <el-select v-model="formData.countyCode" :disabled="configItem.disabled || !isEdit" placeholder="区"
                @change="($event) => onAreaChange($event)">
                <el-option v-for="item in baseOption.countyCode" :key="item.id" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
        </div>
        <!-- -- time date-- -->
        <el-form-item v-else-if="(configItem.type === 'time' || configItem.type === 'date')" :label="configItem.label"
          :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <el-date-picker v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
            :format="configItem.type == 'time' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
            :type="configItem.type === 'time' ? 'datetimerange' : 'daterange'"
            :value-format="configItem.type == 'time' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'" end-placeholder="结束日期"
            range-separator="-" start-placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <!-- -- time date-- -->
        <el-form-item v-else-if="(configItem.type === 'oneDate' || configItem.type === 'oneTime')"
          :label="configItem.label" :prop="configItem.prop" :rules="isEdit ? configItem.rules || null : null"
          class="app-search-item">
          <el-date-picker v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
            :format="configItem.type == 'oneTime' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'"
            :placeholder="configItem.type === 'oneTime' ? '选择时间' : '选择日期'"
            :type="configItem.type === 'oneTime' ? 'time' : 'date'"
            :value-format="configItem.type == 'oneTime' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd'">
          </el-date-picker>
        </el-form-item>
        <!-- -- checkboxTable-- -->
        <el-form-item v-else-if="configItem.type === 'checkboxTable'" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item" label-width="0px">
          <checkboxTable v-model="formData[configItem.prop]" :config="configItem.config" :isEdit="isEdit" />
        </el-form-item>
        <!-- -- image-- -->
        <el-form-item v-else-if="configItem.type === 'image'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <jUploadImg v-if="!loading" v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
            :height="configItem.height" :maxNum="configItem.maxNum" :maxSize="configItem.maxSize" :tip="configItem.tip"
            :tips="configItem.tips" :width="configItem.width">
          </jUploadImg>
        </el-form-item>
        <el-form-item v-else-if="configItem.type === 'image2'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <jUploadImg2 v-if="!loading" v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
            :fileType="configItem.fileType" :height="configItem.height" :maxNum="configItem.maxNum"
            :maxSize="configItem.maxSize" :tip="configItem.tip" :tips="configItem.tips" :width="configItem.width">
          </jUploadImg2>
        </el-form-item>
        <!-- -- file-- -->
        <el-form-item v-else-if="configItem.type === 'file'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <jUploadFile v-if="!loading" v-model="formData[configItem.prop]" :disabled="configItem.disabled || !isEdit"
            :fileType="configItem.fileType" :maxNum="configItem.maxNum" :maxSize="configItem.maxSize"
            :tip="configItem.tip">
          </jUploadFile>
        </el-form-item>
        <el-form-item v-else-if="configItem.type === 'slot'" :label="configItem.label" :prop="configItem.prop"
          :rules="isEdit ? configItem.rules || null : null" class="app-search-item">
          <template>
            <slot :name="configItem.slotName" :row="formData"></slot>
          </template>
        </el-form-item>
        <el-form-item v-else-if="configItem.type === 'text'" class="label-text" :label="configItem.label"
          :prop="configItem.prop">
          <template>
            <copy-text v-if="configItem.copy">{{ formData[configItem.prop1] ? [formData[configItem.prop1] || '', formData[configItem.prop] || ''].join('_') : formData[configItem.prop] }}</copy-text>
            <span v-else>{{ formData[configItem.prop] }}</span>
          </template>
        </el-form-item>
      </el-col>
    </el-row>
    <div v-if="showControl" class="j-form-bottom">
      <el-button size="mini" @click="cancle">取消</el-button>
      <el-button v-if="isEdit" size="mini" type="primary" @click="saveFun">保存</el-button>
    </div>
  </el-form>
</template>
<script>
import checkboxTable from "./components/checkboxTable.vue"
import CopyText from "@/components/jCopyText/index.vue";
export default {
  name: "jForm",
  components: {
    checkboxTable,
    CopyText
  },
  props: {
    jForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    isShow: {
      type: Boolean,
      default: true
    },
    onSubmit: {
      type: Boolean,
      default: true
    },
    baseForm: {
      type: Array,
      default: () => {
        return [
          // { label: '', prop: '', type: '',default:'[]|{}', required: false,disabled:false, placeholder:'',
          // [{ required: true, message: '请输入用户编号/用户名/手机号', trigger: 'change' },
          // { validator: $global.isEmpty }]}
        ]
      }
    },
    pageName: {
      type: String,
      default: ""
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    showControl: {
      type: Boolean,
      default: true
    },
    labelWidth: {
      type: String,
      default: "100px"
    },
    position: {
      type: String,
      default: "top"
    },
    formWidth: {
      type: String,
      default: "100%"
    },
    center: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      formData: {
        provinceCode: undefined,
        cityCode: undefined,
        countyCode: undefined,
      },
      baseOption: {},
      filterText: "",
      loading: false,
      showList: {}
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    isShow: {
      handler(val) {
        if (val) {
          this.init()
        } else {
          this.formData = {}
        }
      },
      immediate: true,
    },
    'formData': {
      handler(newValue, oldValue) {
        // console.log('formData changed:', newValue);
        this.$emit('formDataChange', newValue)
      },
      deep: true
    },
    deep: true
  },
  created() {
    // this.init()
  },
  methods: {
    onAreaChange($event) {
      this.$forceUpdate()
      this.formData.countyName = this.setLabelByName($event, this.baseOption.countyCode)
    },
    init() {
      this.getOption()
    },
    validArea(rule, value, callback) { //验证用户名或昵称经常用到: 只能中英文，数字，下划线，减号
      if (!this.formData.provinceCode) {
        callback(new Error('请选择省份'))
      } else if (!this.formData.cityCode) {
        callback(new Error('请选择城市'))
      } else if (!this.formData.countyCode) {
        callback(new Error('请选择区'))
      } else {
        callback()
      }
    },
    getSubOption(parentId, key) {
      this.$api.regionSubList({ entity: { id: parentId } }).then(res => {
        if (res) {
          this.$set(this.baseOption, key, res)
          // this.$set(this.form, key, '')
        }
      })
    },
    setLabelByName(value, list, label) {
      let labels = ""
      if (Array.isArray(value) && value.length) {
        labels = (list || []).filter(item => value.includes(item.id)).map(item => item.name).join(',')
        return labels
      } else {
        labels = (list || []).find(item => item.id == value) || {}
        return labels.name || ''
      }
    },
    setRules(rules, setRules, prop) {
      if (Array.isArray(rules)) {
        rules[0].validator = (rule, value, callback) => {
          this.$global[setRules](rule, value, callback, this.formData[prop])
        }
      } else {
        rules.validator = (rule, value, callback) => {
          this.$global[setRules](rule, value, callback, this.formData[prop])
        }
      }
      return rules
    },
    async getOption() {
      for (let item of this.baseForm) {
        let prop = item.alias || item.prop
        if (item.list) {
          prop = "list"
        }
        if (['select', 'radio', 'checkbox', 'jMenuTree', 'jSelectTree', 'selects', 'area'].includes(item.type)) {
          switch (prop) {
            case 'sex':
              this.$set(this.baseOption, item.prop, [
                { label: '男', value: 'MALE' },
                { label: '女', value: 'FEMALE' },
              ])
              break;
            case 'isSendMessage':
              this.$set(this.baseOption, item.prop, [
                { label: "是", value: true },
                { label: "否", value: false },
              ])
              break;
            case 'isSync':
              this.$set(this.baseOption, item.prop, [
                { label: "是", value: true },
                { label: "否", value: false },
              ])
              break;
            case 'orgNum':
              this.baseOption[item.prop] = [await this.$api.orgTreeQuery({ entity: {} })]
              break;
            case 'monitorType':
              this.baseOption[item.prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.MONITOR_TYPE.map(item => {
                return { label: item.codeDesc, value: item.codeValue }
              })
              break;
            case 'menuInfos':
              let treeData = await this.$api.treeQueryAll({ entity: {} })
              this.baseOption[item.prop] = treeData[0].subMenus
              break;
            case 'productId':
              let productId = await this.$api.queryProductList({ entity: { productState: "1" } }) || []
              this.baseOption[item.prop] = productId.map(item => {
                return { label: item.name, value: item.id * 1, ...item }
              })
              break;
            case 'deviceTypeValue':
            case 'deviceTypeCode':
              this.baseOption[item.prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_TYPE
                .map(item => {
                  return { label: item.codeDesc, value: item.codeValue }
                })
              break;
            case 'clusteredDeviceType':
              this.baseOption[item.prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_TYPE
                .filter(item => {
                  return ['normal_aggregate', 'deep_displacement_aggregate'].includes(item.codeValue)
                }).map(item => {
                  return { label: item.codeDesc, value: item.codeValue }
                })
              break;
            case 'unclusteredDeviceType':
              this.baseOption[item.prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_TYPE
                .filter(item => {
                  return !['normal_aggregate', 'deep_displacement_aggregate'].includes(item.codeValue)
                }).map(item => {
                  return { label: item.codeDesc, value: item.codeValue }
                })
              break;
            case "connectionTypeValue":
              this.baseOption[item.prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.CONNECTION_TYPE.map(item => {
                return { label: item.codeDesc, value: item.codeValue }
              })
              break;
            case 'manufacturerId':
              let manufacturerId = await this.$api.manufacturerQueryPageall({ entity: {} })
              this.baseOption[item.prop] = manufacturerId.map(item => {
                return { label: item.name, value: item.id }
              })
              break;
            case 'deviceState':
              this.baseOption[item.prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_STATE.map(item => {
                return { label: item.codeDesc, value: item.codeValue }
              })
              break;
            case 'warningStatus':
              this.baseOption[item.prop] = JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
                return { label: item.codeDesc, value: item.codeValue }
              })
              break;
            case 'fatherDeviceCode':
              let fatherDeviceCode = await this.$api.gatewayDeviceList({
                entity: {},
              })
              this.baseOption[item.prop] = (fatherDeviceCode || []).map(item => {
                return { label: item.deviceCode, value: item.deviceCode }
              })
              break;
            case 'monitorProjectId':
              // let monitorProjectId = await this.$api.projectQueryPage({
              //     entity: {},
              //     pageSize: 500,
              //     pageNum: 1,
              // })
              // let monitorProject = monitorProjectId.resultList.map(item => {
              //     return { label: item.name, value: item.id }
              // })
              let monitorProjectId = await this.$api.projectQueryOptions2({ entity: {} })
              let monitorProject = monitorProjectId.map(item => {
                return { label: item?.name, value: item?.id }
              })
              this.baseOption.monitorProjectId = monitorProject
              break;
            case 'admin1UserId':
              let emergencyContactUserId = await this.$api.postQueryOrgUserAll({
                entity: {
                  orgNum: this.$route.query.orgNum
                },
              })
              let emergencyContactUserList = emergencyContactUserId.map(item => {
                return { label: item.username, value: item.id }
              })
              console.log('emergencyContactUserList:', emergencyContactUserList)
              this.baseOption.admin1UserId = emergencyContactUserList
              this.baseOption.admin2UserId = emergencyContactUserList
              this.baseOption.admin3UserId = emergencyContactUserList
              break;
            case 'admin2UserId':
              break;
            case 'admin3UserId':
              break;
            case 'provinceCode':
              let provinceCode = await this.$api.provinceList({ entity: {} })
              if (provinceCode) {
                this.$set(this.baseOption, 'provinceCode', provinceCode)
              }
              if (this.jForm && this.jForm.provinceCode) {
                let cityCode = await this.$api.regionSubList({ entity: { id: this.jForm.provinceCode } })
                cityCode && this.$set(this.baseOption, 'cityCode', cityCode)
              }
              if (this.jForm && this.jForm.cityCode) {
                let countyCode = await this.$api.regionSubList({ entity: { id: this.jForm.cityCode } })
                countyCode && this.$set(this.baseOption, 'countyCode', countyCode)
              }
              break
            case 'bindRoleInfos':
              let params = {
                entity: {},
                pageSize: 10000,
                pageNum: 1
              }
              let res = await this.$api.roleQueryPage(params)
              if (res) {
                let bindRoleInfosOption = res.resultList.map(item => {
                  item.label = item.name
                  item.value = item.id
                  return item
                })
                this.baseOption[item.prop] = bindRoleInfosOption.filter(item => item.isEnable)
              }
              break;
            case 'list':
              let props = item.list.map(cItem => cItem.prop)
              if (props.includes('monitorId')) {
                let monitorInfo = await this.$api.monitorInfoQuerypage({
                  entity: {},
                  pageSize: 10000,
                  pageNum: 1,
                })
                if (monitorInfo) {
                  let tableData = monitorInfo.resultList.map(item => {
                    if (this.formData && (this.formData.monitorId || []).includes(item.id)) {
                      if (item.ministerialPlatformSyncTime) {
                        item.ministerialPlatformSyncTime = new Date(new Date(item.ministerialPlatformSyncTime).getTime()).toLocaleString()
                      } else {
                        item.ministerialPlatformSyncTime = '-'
                      }
                      item.disabled = true
                    }
                    return { label: item.monitorPointName, value: item.id, codeNum: item.monitorPointNum, ...item }
                  })
                  this.baseOption.monitorId = tableData
                }
              }
              if (props.includes('monitorProjectId')) {
                let monitorProjectId = await this.$api.projectQueryPage({
                  entity: {},
                  pageSize: 500,
                  pageNum: 1,
                })
                let monitorProject = monitorProjectId.resultList.map(item => {
                  return { label: item.name, value: item.id }
                })
                this.baseOption.monitorProjectId = monitorProject
              }
              break;
            default:
              this.baseOption[item.prop] = []
              break;
          }
        }
      }
      let isArrays = []
      for (let item of this.baseForm) {
        if (!item.list) {
          if (item.prop) {
            if (Array.isArray(item.default)) {
              isArrays.push(item.prop)
            }
            this.$set(this.formData, item.prop, item.default || "")
          }
        } else {
          for (let cItem of item.list) {
            this.$set(this.formData, cItem.prop, cItem.default || "")
          }
        }

      }
      if (Object.keys(this.jForm)) {
        this.formData = Object.assign(this.formData, this.jForm || {})
        for (let key of Object.keys(this.formData)) {
          if (isArrays.includes(key) && !Array.isArray(this.formData[key])) {
            this.formData[key] = this.formData[key] ? [this.formData[key]] : []
          }
        }
      }
      document.addEventListener('keyup', this.setKeyUp);
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    setKeyUp(event) {
      if (event.key == "Enter") {
        this.saveFun()
      } // 打印按键名称
    },
    eventFun(value, configItem, idx) {
      let params = configItem.change || []
      if (Array.isArray(this.formData[configItem.prop])) {
        for (let key of params) {
          this.formData[key] = []
        }
        let i = 0
        for (let item of this.formData[configItem.prop]) {
          let obj = this.baseOption[configItem.prop].find(cItem => cItem.value == item)
          for (let key of params) {
            this.formData[key].push(obj[key])
          }
          if (i == 0) {
            for (let key in configItem.setFirst) {
              this.formData[key] = obj[key]
            }
          }
          i++
        }
      } else {
        let obj = this.baseOption[configItem.prop].find(item => item.value == value)
        for (let key of params) {
          this.formData[key] = obj[key]
        }
      }
      this.$emit('eventChange', { value, configItem })
    },
    addList(configItem) {
      this.formData[configItem.prop].push('')
      for (let item of configItem.list) {
        if (item.change) {
          for (let key of item.change) {
            this.formData[key].push('')
          }
        }
      }
    },
    delList(configItem, idx) {
      this.formData[configItem.prop].splice(idx, 1)
      for (let item of configItem.list) {
        if (item.change) {
          for (let key of item.change) {
            this.formData[key].splice(idx, 1)
          }
        }
      }
    },
    saveFun() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('save', this.formData)
        } else {
          this.$message.error("必填项未填写完全，请核查")
        }
      })
    },
    cancle() {
      if (this.$route.query.history) {
        this.$router.go(this.$route.query.history)
      } else {
        this.$router.go(-1)
      }
    },
    setFormData({key,value}){
      this.formData[key]=value
      // this.$set(this.formData,key,value)
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    selectOrgId() {
      this.$store.commit('setDlShow', true)
      this.$store.commit('setConfigObj', { name: '选择所属机构', disabled: true })
    },
  },
  beforeDestroy() {
    this.onSubmit && this.$bus.$off('dlSubmit')
    document.removeEventListener('keyup', this.setKeyUp);
  },
}
</script>
<style lang="scss" scoped>
.el-input,
.el-button {
  max-width: 350px;
}

::v-deep {
  .el-divider__text {
    font-family: Alibaba PuHuiTi;
    font-weight: 700;
    color: #000000;
    font-size: 18px!important;
  }

  .el-divider--horizontal {
    margin: 24px 0 30px 0;
  }

  .button-origin span {
    color: $border;
  }

  .el-form-item__label {
    font-family: Alibaba PuHuiTi;
    font-weight: 500;
    color: #000000;
    font-size: 14px;
  }

  .el-input.is-disabled .el-input__inner {
    font-family: Alibaba PuHuiTi;
    color: #000000;
    background-color: #f7f7f7 !important;
    font-size: 14px;
    border: 1px solid;
    border-color: #dbdbdb;
    border-radius: 4px;
  }
}


.label-text ::v-deep .el-form-item__label {
  line-height: 32px;
  font-family: Alibaba PuHuiTi;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

::v-deep .el-input-number,
::v-deep .el-input,
::v-deep .el-select {
  width: 100%;
  max-width: 350px;
}

::v-deep .el-divider__text {
  font-family: "Arial Negreta", "Arial Normal", "Arial", sans-serif;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 3px;
}


::v-deep .el-input .el-input__inner,
::v-deep .el-input,
::v-deep .el-range-editor.el-input__inner {
  max-width: 220px;
}

.j-form-bottom {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

::v-deep .el-form-item__content {
  max-width: unset
}

.selects ::v-deep .el-select {
  width: auto;
}

.selects ::v-deep .el-input {
  max-width: 150px;
}

.el-icon-circle-plus-outline {
  cursor: pointer;
  margin-left: 10px;
  font-size: 18px;
  line-height: 32px;
}

.el-icon-remove-outline {
  cursor: pointer;
  margin-left: 5px;
  font-size: 18px;
  line-height: 32px;
}

.flex-c {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
</style>