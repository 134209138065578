<template>
  <div class="h-100 w-100 p-30" style="position: relative;">
    <div style="cursor: move;">
      <div v-drag="{ _this: index, isFullDl: detailData.isFullDl, v: this }" style="cursor: move;" class="flex-row">
        <span class="dl-title">
          <template v-if="!deviceDataPage">
            <el-tag size="mini" style="margin-right: 5px; margin-top: 5px" effect="plain"
              @click="onCopy(detailData.monitorName, '监测点名称 复制成功!')">{{ detailData.monitorName }}</el-tag>
            <el-tag type="warning" style="margin-right: 5px; margin-top: 5px" effect="plain" size="mini"
              @click="onCopy(detailData.deviceCode, '设备编号 复制成功!')">{{ detailData.deviceCode }}</el-tag>
            <el-tag type="warning" style="margin-right: 5px; margin-top: 5px" effect="plain" size="mini"
              @click="onCopy(detailData.deviceName, '设备名称 复制成功!')">{{ detailData.deviceName }}</el-tag>
            <el-tag :color="getStatesLabel('warningStatus', detailData.warningStatus).color"
              style="margin-right: 5px; margin-top: 5px; color: white" size="mini" v-if="detailData.warningStatus">
              {{ getStatesLabel('warningStatus', detailData.warningStatus).label }}</el-tag>
          </template>
        </span>
        <div class="flex-row" :class="{ 'flex-r': deviceDataPage }">
          <div class="icon el-icon-view" v-if="!deviceDataPage" :class="{ 'active-icon': showExtremeValue }"
            title="显示极值" @click="onExtremeToggle"></div>
          <div class="icon el-icon-refresh" v-if="!['video'].includes(detailData.propertyType)" title="刷新"
            @click="refreshMethod"></div>
          <div class="icon el-icon-download" v-if="!['image', 'video'].includes(detailData.deviceTypeCode)" title="下载"
            @click="downloadMethod">
          </div>
          <!--          <svg-icon class="help" icon-class="big" title="全屏" @click="handleFull(chartData.labelTag)"></svg-icon>-->
          <div class="icon el-icon-full-screen" v-if="!deviceDataPage" title="全屏"
            @click="handleFull(chartData.labelTag)"></div>
          <div class="icon el-icon-circle-close" v-if="!deviceDataPage" title="关闭" @click="close"></div>
        </div>
      </div>
      <div class="flex-row" :class="{ 'flex-sp': deviceDataPage }" v-if="detailData.deviceTypeCode != 'video'">
        <el-radio-group class="tag-title" v-model="chartData.labelTag" size="mini" v-if="deviceDataPage != 'monitor'">
          <el-radio-button label="1">数据曲线</el-radio-button>
          <el-radio-button label="2"
            :disabled="['normal_aggregate', 'deep_displacement_aggregate'].includes(detailData.deviceTypeCode)">数据列表</el-radio-button>
        </el-radio-group>
        <div class="flex-row">
          <h3 v-if="deviceDataPage" style="margin-right: 10px;">快捷选择</h3>
          <el-radio-group v-model="tagTime" size="small" style="margin-right:5px" @input="setTagTime($event, '1')">
            <el-radio-button label="1">1天</el-radio-button>
            <el-radio-button label="7">1周</el-radio-button>
            <el-radio-button label="30">1月</el-radio-button>
          </el-radio-group>
          <el-date-picker v-model="datetimerange" type="datetimerange" range-separator="-" size="mini"
            @change="setTagTime($event)" style="width:200px" start-placeholder="开始日期" end-placeholder="结束日期"
            align="right" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :clearable="false">
          </el-date-picker>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="h-100 w-100" v-if="detailData.deviceTypeCode == 'video'">
      <div style="padding:15px 0 0 0 ;">
        <div v-drag="{ _this: index, isFullDl: detailData.isFullDl, v: this }" style="cursor: move;d"
          class="flex-start-row"><span v-if="chartImage && chartImage.length && chartData.labelTag == '1'"
            style="font-size: 13px;">视频时间:</span>
          <span style="margin-left: 10px;color:#91cc75;font-size: 13px;">{{ newImageTime }}</span>
        </div>
      </div>
      <div class="warp-image" v-if="chartImage && chartImage.length && chartData.labelTag == '1'">
        <video class="h-100 w-100" :src="chartImage.videoFileId" control autoplay></video>
      </div>
      <el-empty class="h-100 w-100" style="padding:15px 0; height: 400px" v-else></el-empty>
    </div>
    <div class="h-100 w-100" v-loading="loading"
      v-else-if="detailData.deviceTypeCode == 'image' && detailData.propertyType == 'MONITOR'">
      <div style="padding:15px 0 0 0 ;">
        <div v-drag="{ _this: index, isFullDl: detailData.isFullDl, v: this }" style="cursor: move;"
          class="flex-start-row">
          <span v-if="chartImage && chartImage.length && chartData.labelTag == '1'"
            style="font-size: 13px;">最新的数据:</span>
          <span style="margin-left: 10px;color:#91cc75;font-size: 13px;">{{ newImageTime }}</span>
        </div>
      </div>
      <div class="warp-image" v-if="chartImage && chartImage.length && chartData.labelTag == '1'">
        <div v-for="(item, i) in chartImage" class="image-item" :key="i">
          <el-image :src="item.imageFileId" :preview-src-list="srcList">
          </el-image>
          <span class="circle"></span>
          <span>{{ item.uploadTime }}</span>
        </div>
      </div>
      <jTable v-else-if="chartImage && chartImage.length && chartData.labelTag == '2'" v-model="columnConfig2"
        class="j-table-dialog" :height="detailData.isFullDl ? '' : '400px'" ref="jTable"
        :class="{ 'j-table-dialog-full': detailData.isFullDl }" :tableData="chartImage" :total="total" @search="search"
        :controlName="''">
      </jTable>
      <el-empty class="h-100 w-100" style="padding:15px 0; height: 400px" v-else></el-empty>
    </div>
    <div class="h-100 w-100" v-loading="loading" v-else-if="chartList">
      <div v-if="chartData.labelTag == '1' && showEchartDl && chartDatas.length" class="h-100 w-100 flex-st down-chart"
        style="padding:15px 0 0 0 ;">
        <div style="position: relative;margin-top: 20px;" v-for="(item, i) in chartDatas" :key="i"
          :style="{ width: 100 / rowNum + '%', height: echartH + 'px' }">
          <div :id="'container' + i" style="width:100%;height: 100%;" v-if="item?.properties?.length"></div>
          <span class="f-t-15" v-if="item?.properties?.length">{{ item.deviceName }}({{ item.deviceCode }})</span>
          <el-empty class="h-100 w-100" style="padding:15px 0;" v-else></el-empty>
        </div>
      </div>
      <div v-else-if="tableData.length && chartData.labelTag == '2'" class="h-100 w-100">
        <jTable v-model="columnConfig" class="j-table-dialog" :height="detailData.isFullDl ? '' : '400px'"
          v-if="showEchartDl" :class="{ 'j-table-dialog-full': detailData.isFullDl }" :tableData="tableData"
          :total="total" @search="search" :controlName="''">
        </jTable>
      </div>
      <el-empty class="h-100 w-100" style="padding:15px 0; height: 400px" v-else></el-empty>
    </div>
    <div class="h-100 w-100" v-loading="loading" v-else>
      <el-form ref="formName" label-position="left"
        v-if="!deviceDataPage && chartData.labelTag == '1' && !['normal_aggregate'].includes(detailData.deviceTypeCode)"
        style="margin-top: 5px;">
        <el-form-item label="选择分组" label-width="75px">
          <el-select clearable filterable v-model="groupNameList" style="width:auto" multiple placeholder="选择分组"
            @change="changePrototype">
            <el-option v-for="item in dataOption" :key="item" :label="item == '-' ? '默认分组' : item" :value="item">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div v-if="this.nowInfo.length && chartData.labelTag == '1'" class="h-100 w-100 down-chart"
        style="padding:15px 0 0 0 ;">
        <div v-if="showEchartDl" :id="'container' + index"
          :style="{ height: detailData.isFullDl ? 'calc(100% - 100px)' : '400px' }">
        </div>
        <div v-drag="{ _this: index, isFullDl: detailData.isFullDl, v: this }" style="cursor: move; padding: 10px 0"
          class="flex-start-row"><span style="font-size: 13px;">最新的数据:</span>
          <span v-for="(item, i) in nowInfo" style="margin-left: 10px;color:#91cc75;font-size: 13px;">{{ item.name }}:{{
            item.value }}</span>
        </div>
      </div>
      <div v-else-if="tableData.length && chartData.labelTag == '2'" class="h-100 w-100">
        <jTable v-model="columnConfig" class="j-table-dialog" :height="detailData.isFullDl ? '' : '400px'"
          v-if="showEchartDl" :class="{ 'j-table-dialog-full': detailData.isFullDl }" :tableData="tableData"
          :total="total" @search="search" :controlName="''">
        </jTable>
      </div>
      <el-empty class="h-100 w-100" style="padding:15px 0; height: 400px" v-else></el-empty>
    </div>

  </div>
</template>

<script>
import * as echarts from 'echarts'
import html2canvas from 'html2canvas'
export default {
  name: 'echartline',
  props: {
    detailData: {
      type: Object,
      default: () => { }
    },
    index: {
      type: Number,
      default: 0
    },
    rowNum: {
      type: String,
      default: "1"
    },
    deviceDataPage: {
      type: [Boolean, String],
      default: false
    },
    chartList: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      chartData: {
        labelTag: '1',
      },
      chartImage: [],
      newImageTime: "",
      srcList: [],
      groupNameList: [],
      datetimerange: [this.$tool.getFormatTime(1), this.$tool.getFormatTime(0)],
      loading: true,
      total: 0,
      tableData: [],
      columnConfig: [],
      columnConfig2: [
        { label: "时间", prop: "createTime" },
        { label: "记录图像", prop: "imageFileId", previewPigImage: '查看大图', width: 150 },
      ],
      // showTable: false,
      keyList: [],
      timestamps: [],
      dataOption: [],
      tagTime: "1",
      nowInfo: [],
      myChart: {},
      chartDatas: [],
      showEchartDl: true,
      showExtremeValue: false,
    }
  },
  // 自定义指令 —— 拖动div
  directives: {
    drag(el, info) {
      info.value.v.$nextTick(() => {
        let oDiv = el // 当前元素
        document.onselectstart = function () {
          return false
        }
        let prentDiv = document.getElementsByClassName('echartWarp' + info.value._this)[0]
        if (prentDiv) {
          oDiv.onmousedown = function (e) {
            // 鼠标按下，计算当前元素距离可视区的距离
            let disX = e.clientX - prentDiv.offsetLeft
            let disY = e.clientY - prentDiv.offsetTop
            document.onmousemove = function (e) {
              // 通过事件委托，计算移动的距离
              let l = e.clientX - disX
              let t = e.clientY - disY
              // 移动当前元素
              if (!info.value.isFullDl) {
                prentDiv.style.left = l + 'px'
                prentDiv.style.top = t + 'px'
              }
            }
            document.onmouseup = function (e) {
              document.onmousemove = null
              document.onmouseup = null
              document.onselectstart = function () {
                return true
              }
            }
            return false
          }
        }
      })
    }
  },
  computed:{
    echartH(){
      let element = document.getElementsByClassName('person-right')[0]
      let height  =window.getComputedStyle(element).width.replace('px', '')*4/(this.rowNum*7)
      console.log(window.getComputedStyle(element).width.replace('px', ''),height,'height')
      return parseInt(height)
    }
  },
  mounted() {
    if (!['normal_aggregate', 'deep_displacement_aggregate', 'image'].includes(this.detailData.deviceTypeCode) && !this.chartList)
      this.getPropertyGroupList()
  },
  watch: {
    'chartData.labelTag': {
      handler(val) {
        // this.tagTime = ""
        this.init()
      },
      immediate: true,
    },
    "detailData.isFullDl": {
      handler(val) {
        this.showEchartDl = false
        this.$nextTick(() => {
          this.showEchartDl = true
        })
      },
    },
  },
  destroyed() {
    this.tagTime = ""
  },
  methods: {
    changePrototype() {
      this.init()
    },
    getPropertyGroupList() {
      if (!this.detailData.productId) return
      this.$api.propertyGroupList({ entity: { productId: this.detailData.productId } }).then(res => {
        if (res) {
          this.dataOption = res || res.resultList
        }
      })
    },
    setMapImgUrl(id) {
      let baseUrl = window.location.href.split('map')[0]
      if (process.env.NODE_ENV == 'development') {
        baseUrl = this.URL_LIST.ImageUrl
      }
      return baseUrl + 'api' + id + '?access_token=' + sessionStorage.getItem('access_token').replace('bearer ', '')
    },
    setToken() {
      let access_token = sessionStorage.getItem('access_token').replace('bearer ', '')
      return '?access_token=' + access_token
    },
    onExtremeToggle() {
      this.showExtremeValue = !this.showExtremeValue;
      this.setOptions(this.chartData.seriesData); // 重新设置图表，应用或移除极值标记
    },
    getStatesLabel(key, value) {
      if (!value && value != 0) {
        if (key == 'top' || key == 'isTop') {
          value = "0"
        } else {
          return '-'
        }
      }
      let warningStatusColorMap = {
        "0": "#33bf09",
        "1": "#1296db",
        "2": "#ffbb36",
        "3": "#ff8000",
        "4": "#d81e06",
        "-1": "#787b80",
      };
      let states = {
        warningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
        monitorWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
        deviceWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
      }
      let labels = []
      labels = states[key].filter(item => {
        if (typeof (value) == 'number' || typeof (value) == 'boolean') {
          return value + "" == item.value + ''
        } else {
          return value == item.value + ''
        }
      })
      let labelStr = [], color = ''
      for (let item of labels) {
        labelStr.push(item.label)
        if (item.color) {
          color = item.color
        }
      }
      labelStr = labelStr.join(',')
      return { label: labelStr.length ? labelStr : '', color: color }
    },
    onCopy(data, msg) {
      let _msg = msg ? msg : '复制成功!';
      if (window.clipboardData) {
        window.clipboardData.setData('text', data);
        this.$message.success(_msg)
      } else {
        (function () {
          document.oncopy = function (e) {
            e.clipboardData.setData('text', data);
            e.preventDefault();
            document.oncopy = null;
          }
        })('要复制的内容');
        document.execCommand('Copy');
        this.$message.success(_msg)
      }
    },
    close() {
      this.$emit('close', this.detailData)
    },
    handleFull(labelTag) {
      this.$emit('handleFull', labelTag)
      this.showEchartDl = false;
      this.$nextTick(() => {
        this.showEchartDl = true;
        this.refreshEcharts();
      });
    },
    setTagTime(e, type) {
      if (type) {
        this.datetimerange = [this.$tool.getFormatTime(this.tagTime * 1), this.$tool.getFormatTime(0)]
      } else {
        this.tagTime = ""
      }
      if (this.chartData.labelTag == '1') {
        if (!['normal_aggregate', 'deep_displacement_aggregate', 'image'].includes(this.detailData.deviceTypeCode)) {
          this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
          this.queryDateByPropertyGroup({ pageNum: 1, pageSize: 50 })
          return
        }
        this.queryChart()
      } else {
        this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
        this.queryList({ pageNum: 1, pageSize: 50 })
      }
    },
    init() {
      // this.datetimerange = [this.$tool.getFormatTime(15), this.$tool.getFormatTime(0)]
      if (this.chartData.labelTag == '1') {
        if (!['normal_aggregate', 'deep_displacement_aggregate', 'image'].includes(this.detailData.deviceTypeCode)) {
          this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
          this.queryDateByPropertyGroup({ pageNum: 1, pageSize: 50 })
          return
        }
        this.queryChart()
      } else {
        this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
        this.queryList({ pageNum: 1, pageSize: 50 })
      }
    },
    queryDateByPropertyGroup(formData) { //分组查询设备数据
      this.chartDatas = []
      if (this.chartList) {
        this.queryDateByPropertyGroupMulChart(formData)
      } else {
        this.queryDateByPropertyGroupOneChart(formData)
      }
    },
    queryDateByPropertyGroupMulChart(formData) {
      let entity = {
        monitorId: this.detailData.monitorId,
        devicePropertyGroups: this.detailData.devicePropertyGroups,
        timeRange: {}
      }
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        entity.timeRange.startTime = new Date(this.datetimerange[0]).getTime()
        entity.timeRange.endTime = new Date(this.datetimerange[1]).getTime()
      }
      this.$api.propertyGroupQueryData({ entity }).then(resData => {
        if (resData?.entity) {
          let i = 0
          for (let chart of resData.entity || []) {
            this.intDataForChart(chart, this.chartDatas, true, i)
            i += 1
          }
        }
        this.loading = false;
      })
    },
    queryDateByPropertyGroupOneChart(formData) {
      this.loading = true
      let entity = {
        deviceCode: this.detailData.deviceCode,
        groupNameList: this.detailData.groupNameList || this.groupNameList,
        dataShowcaseType: this.detailData.propertyType,
      }
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        entity.startTime = new Date(this.datetimerange[0]).getTime()
        entity.endTime = new Date(this.datetimerange[1]).getTime()
      }
      this.$api.propertyGroupData({ entity }).then(resData => {
        if (resData) {
          this.chartData.seriesData = []
          this.intDataForChart(resData, this.chartData.seriesData)
        }
        this.loading = false;
      })
    },
    intDataForChart(resData, seriesDatas, isArray, classId) {
      let timestamps = []
      this.nowInfo = []
      let seriesData = []
      for (let item of resData.properties || resData || []) {
        let records = item?.records || item
        if (records?.length) {
          var values = records.map(cItem => {
            // this.timestamps.push(this.convertToBeijingTime(cItem.timestamp))
            timestamps.push(cItem.timestamp)
            return [cItem.timestamp, cItem.value, item.unit || cItem.unit || ''];
          });
          let propertyName = Array.isArray(item) && !!item.length ? item[0].propertyName : item?.propertyName
          if (propertyName) {
            this.nowInfo.push({ name: propertyName, value: values[0][1] + values[0][2] })
            seriesData.push({
              name: propertyName,
              type: 'line',
              symbolSize: 5,
              data: values,
              markPoint: {
                data: []
              },
            })
          }
        }
      }
      if (seriesData.length) {
        seriesData = seriesData.map(series => {
          if (series.data.length > 2) {
            // 添加新点
            const latestDataPoint = series.data[0];
            const newTimeStamp = series.data[0][0] + (series.data[0][0] - series.data[1][0]);
            const newDataPoint = [newTimeStamp, latestDataPoint[1], latestDataPoint[2]];
            const newSeries = { ...series };
            newSeries.data.unshift(newDataPoint);
            // 更新时间戳
            timestamps.push(newTimeStamp);
            // 调整标记symbol的size
            newSeries.symbolSize = function (data, param) {
              return param.dataIndex === 0 ? 0 : series.symbolSize;
            };
            // 调整最大最小值 mark point
            series.markPoint.data = [
              ...this.getMinMarkPoint(series.data),
              ...this.getMaxMarkPoint(series.data)
            ]
            return newSeries;
          } else if (series.data.length == 1) {
            return series;
          }
        });
      }
      this.timestamps = [this.convertToBeijingTime(Math.min.apply(null, timestamps)), this.convertToBeijingTime(Math.max.apply(null, timestamps))]
      if (isArray) {
        seriesDatas.push({ ...seriesData, deviceName: resData.deviceName, deviceCode: resData.deviceCode,properties:resData.properties })
      } else {
        if (this.deviceDataPage) {
          seriesDatas.push({ ...seriesData, deviceName: resData.deviceName, deviceCode: resData.deviceCode,properties:resData.properties })
        } else {
          this.chartData.seriesData = seriesData
        }
      }
      console.log(seriesDatas,'seriesDatas')
      this.setOptions(seriesData, classId)
    },
    refreshEcharts() {
      if (this?.chartDatas?.length) {
        for (let i = 0; i < this.chartDatas.length; i++) {
          this.setOptions(this.chartData.seriesData, i);
        }
      } else {
        this.setOptions(this.chartData.seriesData);
      }
    },
    setTableLabel() {
      let columnConfig = []
      for (let key of this.keyList) {
        if (key == 'timestamp') {
          columnConfig.unshift({ label: '时间', prop: key, notValue: "-" })
        } else {
          columnConfig.push({ label: key, prop: key, notValue: "-" })
        }
      }
      return columnConfig
    },
    queryChart() {
      if (this.detailData.deviceTypeCode == 'image' && this.detailData.propertyType == 'MONITOR') {
        this.getImage()
      } else {
        this.queryDevicePropertiesData()
      }
    },
    queryList(formData) {
      if (this.detailData.propertyType == 'image' || this.detailData.deviceTypeCode == 'image') {
        this.getImagePage(formData)
      } else {
        this.queryDevicePropertiesDataPage(formData)
      }
    },
    getImage() {
      this.loading = true
      let entity = {
        deviceCode: this.detailData.deviceCode,
      }
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        entity.startTime = this.datetimerange[0]
        entity.endTime = this.datetimerange[1]
      }

      this.$api.getImage({ entity }).then(resData => {
        this.loading = false
        if (resData) {
          this.chartImage = resData.map(item => {
            item.imageFileId = this.$FileBaseURL + '/file/get/' + item.imageFileId + this.setToken()
            return item
          })
          this.newImageTime = this.chartImage[this.chartImage.length - 1]?.uploadTime
          this.srcList = (resData || []).map(item => item.imageFileId)
        }
      })
    },
    getImagePage(formData) {
      this.loading = true
      let params = {
        entity: { deviceCode: this.detailData.deviceCode, },
        pageSize: formData.pageSize,
        pageNum: formData.pageNum
      }
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        params.entity.startTime = this.datetimerange[0]
        params.entity.endTime = this.datetimerange[1]
      }
      this.$api.getImagePage(params).then(resData => {
        this.loading = false
        if (resData) {
          this.chartImage = (resData.resultList || []).map(item => {
            item.imageFileId = this.$FileBaseURL + '/file/get/' + item.imageFileId + this.setToken()
            return item
          })
          this.total = resData.totalNum
        }
      })
    },
    queryDevicePropertiesData() {
      this.loading = true
      let entity = {
        deviceCode: this.detailData.deviceCode,
        // propertys: this.detailData.propertys,
        propertyType: this.detailData.propertyType == 'STATE' ? 'STATE' : 'MONITOR',
      }
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        let startTime = this.datetimerange[0]
        let endTime = this.datetimerange[1]
        if (startTime && endTime) {
          entity.where = `createTime<=${new Date(endTime).getTime()} and createTime>=${new Date(startTime).getTime()}`
        }
      }
      this.$api.queryDevicePropertiesData({ entity }).then(resData => {
        if (resData) {
          if(this.deviceDataPage){
            this.intDataForChart(resData,this.chartDatas)
          }else{
            this.intDataForChart(resData)
          }
        }
        this.loading = false;
      })
    },
    getMinMarkPoint(data) {
      let extremum = null;
      let index = -1;
      data.forEach((point, i) => {
        if (i === 0) return; // 跳过第一个数据点
        if (extremum === null || point[1] < extremum) {
          extremum = point[1];
          index = i;
        }
      });

      if (index !== -1) {
        return [{
          name: '最小值',
          value: extremum,
          xAxis: data[index][0],
          yAxis: extremum,
          symbolOffset: [0, 15],
          symbolSize: [20, 10],  // 调整图标大小
          symbol: 'path://M605.556364 265.914182L519.074909 512h-14.242909L423.563636 264.750545l-39.237818 242.548364H325.818182L383.813818 139.636364h43.194182l89.832727 246.085818L602.717091 139.636364h43.706182L698.181818 507.252364H639.674182l-34.164364-241.338182zM791.272727 512H744.727273V279.272727h46.545454v232.727273z m97.559273-245.76c14.382545-22.341818 35.607273-33.512727 63.627636-33.512727C1000.168727 232.727273 1024 270.801455 1024 347.042909V512h-50.501818V368.965818c0-57.111273-13.498182-85.597091-40.494546-85.597091-29.463273 0-44.171636 30.580364-44.171636 91.694546V512H837.818182V241.664h51.013818v24.576zM744.727273 186.181818h46.545454v46.545455H744.727273V186.181818z',
          label: {
            show: true,
            position: 'bottom',  // Position the label below the symbol
          }
        }];
      }
      return [];
    },
    getMaxMarkPoint(data) {
      let extremum = null;
      let index = -1;

      data.forEach((point, i) => {
        if (i === 0) return; // 跳过第一个数据点
        if (extremum === null || point[1] > extremum) {
          extremum = point[1];
          index = i;
        }
      });

      // 如果找到极值，返回标记点配置
      if (index !== -1) {
        return [{
          name: '最大值',
          value: extremum,
          xAxis: data[index][0],
          yAxis: extremum,
          symbolOffset: [0, -25],
          symbolSize: [20, 10],  // 调整图标大小
          symbol: 'path://M617.192727 265.914182L541.463273 512h-12.427637L458.007273 264.704 423.703273 507.345455H372.363636L423.144727 139.636364h37.794909l78.661819 246.085818L614.586182 139.636364h38.306909L698.181818 507.298909H646.981818l-29.789091-241.384727zM884.363636 504.832h-43.985454v-19.921455c-12.986182 18.106182-32.302545 27.089455-57.949091 27.089455-25.6 0-46.08-11.822545-61.346909-35.374545C705.815273 453.026909 698.181818 425.425455 698.181818 393.774545c0-31.650909 7.773091-58.693818 23.365818-80.98909 15.592727-22.341818 35.607273-33.466182 59.997091-33.466182 25.925818 0 45.521455 9.262545 58.833455 27.601454v-20.386909H884.363636v218.391273-0.046546z m-46.545454-109.521455c0-20.200727-4.375273-36.817455-13.125818-49.850181-7.959273-12.660364-20.48-19.921455-33.652364-19.642182-13.032727-0.372364-25.413818 6.702545-33.373091 19.083636C749.009455 357.562182 744.727273 374.365091 744.727273 395.403636c0 20.945455 4.375273 37.841455 13.125818 50.734546 8.098909 12.474182 20.619636 19.642182 33.838545 19.316363 12.986182 0.465455 25.367273-6.516364 33.419637-18.757818 8.471273-12.567273 12.706909-29.602909 12.706909-51.293091v-0.093091zM901.306182 279.272727h61.486545l42.030546 65.396364L1040.756364 279.272727h63.022545l-70.702545 105.425455L1117.090909 512h-62.557091l-55.389091-87.179636-51.2 87.179636H884.363636l86.109091-126.603636L901.306182 279.272727z',
          label: {
            show: true,
            position: 'bottom',  // Position the label below the symbol
          }
        }];
      }

      return [];
    },
    setOptions(seriesData, classId = this.index) {
      let _this = this;
      // 重新计算可见系列的最小和最大y轴值
      function getVisibleYAxisRange() {
        let visibleSeriesData = seriesData.filter(series => {
          // 获取当前图例的可见状态
          let legend = _this.myChart.getOption().legend[0].selected;
          return legend[series.name] !== false; // 检查当前图例是否被选中
        });

        let yValues = visibleSeriesData.reduce((acc, series) => {
          return acc.concat(series.data.map(point => point[1]));
        }, []);

        let yMin = Math.min(...yValues);
        let yMax = Math.max(...yValues);

        // 在最小和最大值基础上增加一些padding
        let yPadding = (yMax - yMin) * 0.2;

        return {
          yMin: Math.floor((yMin - yPadding) * 10) / 10,
          yMax: Math.ceil((yMax + yPadding) * 10) / 10,
        };
      }

      let yValues = seriesData.reduce((acc, series) => {
        return acc.concat(series.data.map(point => point[1]));
      }, []);
      let yMin = Math.min(...yValues);
      let yMax = Math.max(...yValues);

      // 在最小和最大值基础上增加一些padding
      let yPadding = (yMax - yMin) * 0.2;

      yMin = Math.floor((yMin - yPadding) * 10) / 10;
      yMax = Math.ceil((yMax + yPadding) * 10) / 10;

      // 判断数据量，看是否要关闭加载动画
      let dataNum = 0;
      _this.chartData.seriesData.forEach(series => {
        dataNum = dataNum + ( series?.data ? series.data.length : 0 );
      });
      const isLargeData = dataNum > 1000;
      console.log('_this.chartData.seriesData.length', _this.chartData.seriesData, dataNum);
      let option = {
        animation: !isLargeData,
        tooltip: {
          trigger: 'axis',
          // formatter: function (params) {
          //   let timeString = `时间: ${_this.convertToBeijingTime(params[0].data[0])}<br/>`;
          //   let valueStrings = params.map(param => {
          //     let color = param.color; // 获取该数据项对应的颜色
          //     return `<span style="display:inline-block;width:10px;height:10px;background-color:${color};margin-right:5px;"></span>${param.seriesName}: ${param.value[1]} ${param.value[2]}`;
          //   }).join('<br/>');
          //   return timeString + valueStrings;
          // }
          formatter: function (params) {
            // 排除第一个点（新添加的点）不显示 tooltip
            let validParams = params.filter(param => param.dataIndex !== 0);
            if (validParams.length === 0) return ''; // 如果没有有效数据点，则不显示 tooltip

            let timeString = `时间: ${_this.convertToBeijingTime(validParams[0].data[0])}<br/>`;
            let valueStrings = validParams.map(param => {
              let color = param.color; // 获取该数据项对应的颜色
              return `<span style="display:inline-block;width:10px;height:10px;background-color:${color};margin-right:5px;"></span>${param.seriesName}: ${param.value[1]} ${param.value[2]}`;
            }).join('<br/>');
            return timeString + valueStrings;
          }
        },
        legend: {
          data: seriesData.map(series => series.name),
          left: '8%', // 将图例放置在顶部，避免与 dataZoom 重叠
          right: '8%', // 将图例放置在顶部，避免与 dataZoom 重叠
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '12%',
          containLabel: true
        },
        toolbox: {
          feature: {
            dataZoom: {}, // To zoom in/out
          }
        },
        dataZoom: [
          {
            // startValue: this.timestamps[0],
            // endValue: this.timestamps[this.timestamps.length - 1],
            // preventDefaultMouseMove: true
            type: 'slider',  // 显示下方的滑动条
            start: 0,        // 显示范围从 0% 开始
            end: 100,        // 显示范围到 100% 结束
            xAxisIndex: 0,
            preventDefaultMouseMove: true
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            zoomOnMouseWheel: true, // 滚轮是否触发缩放
            preventDefaultMouseMove: true
          }
        ],
        xAxis: {
          type: 'time',
          boundaryGap: ['5%', '5%'], // Add this line to leave space on both sides
        },
        // yAxis: {
        //   // min: yMin - yPadding, // 将Y轴的最小值减去padding
        //   // max: yMax + yPadding, // 将Y轴的最大值加上padding
        //   // min: Math.floor(yMin - yPadding), // 向下取整并减去padding
        //   // max: Math.ceil(yMax + yPadding), // 向上取整并加上padding
        //   min: Math.floor((yMin - yPadding) * 10) / 10, // 向下取整到小数点后一位
        //   max: Math.ceil((yMax + yPadding) * 10) / 10,  // 向上取整到小数点后一位
        //   minInterval: 0.5,
        // },
        yAxis: {
          min: yMin,
          max: yMax,
          minInterval: 0.5,
        },
        // series: _this.chartData.seriesData
        series: seriesData.map(series => {
          series.large = true;
          if (_this.showExtremeValue) {
            series.markPoint = {
              data: [
                ..._this.getMinMarkPoint(series.data),
                ..._this.getMaxMarkPoint(series.data)
              ]
            };
          } else {
            // delete series.markPoint;
            series.markPoint = null;  // 确保 markPoint 被完全移除
          }
          return series;
        })
      }
      console.log(option, 'option')
      this.$nextTick(() => {
        let dom = document.getElementById('container' + classId);
        this.myChart = echarts.init(dom);
        if (option) {
          this.myChart.setOption(option, true);
        }
        // 监听图例状态变化事件
        this.myChart.on('legendselectchanged', (event) => {
          let { selected } = event;

          // 遍历并重新设置可见series的markPoint
          seriesData.forEach(series => {
            // large模式
            series.large = isLargeData;
            if (selected[series.name] && _this.showExtremeValue) {
              // 只为可见的曲线设置 markPoint
              series.markPoint = {
                data: [
                  ..._this.getMinMarkPoint(series.data),
                  ..._this.getMaxMarkPoint(series.data)
                ]
              };
            } else {
              series.markPoint = null;  // 删除 markPoint
            }
          });

          // 重新计算可见曲线的 Y 轴范围
          let { yMin, yMax } = getVisibleYAxisRange();

          // 更新图表的 Y 轴范围以及 series 数据
          _this.myChart.setOption({
            yAxis: {
              min: yMin,
              max: yMax
            },
            series: seriesData // 重新设置更新后的 series
          });
        });


        window.addEventListener('resize', this.myChart.resize);
      })
    },
    convertToBeijingTime(timestamp) {
      return new Date(timestamp).toLocaleString()
    },
    search(formData) {
      this.queryList(formData)
    },
    async queryDevicePropertiesDataPage(formData) {
      this.loading = true;
      let params = {
        entity: formData || {},
        pageSize: formData.pageSize,
        pageNum: formData.pageNum
      }
      params.entity.deviceCode = this.detailData.deviceCode
      // params.entity.propertys = this.detailData.propertys
      params.entity.propertyType = this.detailData.propertyType == 'STATE' ? 'STATE' : 'MONITOR'
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        let startTime = this.datetimerange[0]
        let endTime = this.datetimerange[1]
        if (startTime && endTime) {
          params.entity.where = `createTime<=${new Date(endTime).getTime()} and createTime>=${new Date(startTime).getTime()}`
        }
      }
      if (!params.entity.deviceCode) return
      delete params.entity.pageSize
      delete params.entity.pageNum
      this.showTable = false
      let res = await this.$api.queryDevicePropertiesDataPage(params)
      if (res) {
        this.keyList = [];
        this.tableData = (res.resultList || []).map(item => {
          item.timestamp = new Date(item.timestamp * 1).toLocaleString()
          for (let key of Object.keys(item) || []) {
            !this.keyList.includes(key) && this.keyList.push(key)
          }
          return item
        })
        this.keyList = res.titleList ? res.titleList : this.keyList;
        this.columnConfig = this.setTableLabel()
        this.total = res.totalNum
        // this.$nextTick(()=>{
        //     this.showTable = true
        // })
      }
      this.loading = false
    },
    downloadMethod() {
      if (this.chartData.labelTag == '1') {
        if (this.deviceDataPage == "monitor" || this.detailData.deviceTypeCode == "image") {
          this.exportEchartMul()
          return
        }
        this.exportEchart()
      } else {
        this.exportTableData()
      }
    },
    refreshMethod() {
      if (this.chartData.labelTag == '1') {
        if (!['normal_aggregate', 'deep_displacement_aggregate', 'image'].includes(this.detailData.deviceTypeCode)) {
          this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
          this.queryDateByPropertyGroup({ pageNum: 1, pageSize: 50 })
          return
        }
        this.queryChart()
      } else {
        this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
        this.queryList({ pageNum: 1, pageSize: 50 })
      }
    },
    async exportEchartMul() {
      let dom = document.getElementsByClassName("down-chart")[0]
      let canvasImage = await html2canvas(dom)
      const imageData = canvasImage.toDataURL("image/png")
      const downloadLink = document.createElement('a')
      downloadLink.download = this.detailData.monitorName + '-' + (this.detailData.propertyType == 'STATE' ? '设备状态' : '监测数据');
      downloadLink.href = imageData
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    },
    // 导出单个图表图片
    exportEchart() {
      var img = new Image();
      img.src = this.myChart.getDataURL({
        type: "png",
        pixelRatio: 1, //放大2倍
        backgroundColor: "#fff",
      });
      let _this = this
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d", { willReadFrequently: true });
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        var a = document.createElement("a");
        var event = new MouseEvent("click");
        a.download = _this.detailData.deviceName + '-' + (_this.detailData.propertyType == 'STATE' ? '设备状态' : '监测数据');
        // 将生成的URL设置为a.href属性
        a.href = dataURL;
        // 触发a的单击事件
        a.dispatchEvent(event);
        a.remove();
      };
    },
    exportTableData() {
      let entity = {
        deviceCode: this.detailData.deviceCode,
        // propertys: this.detailData.propertys,
        propertyType: this.detailData.propertyType == 'STATE' ? 'STATE' : 'MONITOR',
      }
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        let startTime = this.datetimerange[0]
        let endTime = this.datetimerange[1]
        if (startTime && endTime) {
          entity.where = `createTime<=${new Date(endTime).getTime()} and createTime>=${new Date(startTime).getTime()}`
        }
      }
      this.$api.downloadEchartData({ entity }).then(res => {
        if (res) {
          let fileName = this.detailData.deviceName + '-' + (this.detailData.propertyType == 'STATE' ? '设备状态' : '监测数据');
          this.$tool.downFileByUrl(res.fileId, fileName)
        }

      })
    },
  },
}
</script>

<style scoped lang="scss">
.f-t-15 {
  position: absolute;
  right: 0;
  top: -25px;
  display: block;
  font-size: 14px;
  font-weight: 600;
}

.tag-title {
  //position: absolute;
  //left: 0px;
  //top: - 35px;
}

.el-form-item__label {
  font-family: Alibaba PuHuiTi;
  font-weight: 700;
  color: #343434;
  font-size: 12px;
}

.warp-image {
  // margin: px 0;
  width: 100%;
  min-height: 250px;
  box-sizing: border-box;
  padding: 40px 0 130px 40px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-x: scroll;

  .image-item {
    width: 50px;
    min-width: 50px;
    height: 100px;
    border-bottom: 2px solid #999;
    border-left: 2px dashed #999;
    position: relative;
  }

  .image-item:last-child {
    border-bottom: 0;
  }

  .circle {
    width: 6px;
    height: 6px;
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 50%);
    border-radius: 6px;
    background-color: #fff;
    border: 2px solid #1296db;
  }

  span {
    display: block;
    position: absolute;
    font-size: 11px;
    color: rgb(138 200 232);
    left: 0;
    bottom: 0;
    transform: translate(-40px, 65px) rotate(75deg);
    white-space: nowrap;
  }

  ::v-deep .el-image {
    width: 45px;
    height: 45px;
    position: absolute;
    border-radius: 8px;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%)
  }
}

.j-table-dialog {
  max-height: 450px;
  overflow-y: scroll;

}

.flex-r {
  justify-content: flex-end;
  width: 100%;
}

.j-table-dialog-full {
  max-height: calc(100% - 100px) !important;
  min-height: calc(100% - 100px) !important;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
  padding: 7px 15px;
}

::v-deep .el-date-editor--datetimerange.el-input__inner {
  width: 320px;
}

.dl-title {
  line-height: 16px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 14px;
  // color:#91cc75;
  font-weight: 600;
}

//.el-icon-circle-close,
//.help,
//.el-icon-refresh,
//.el-icon-download {
//  display: block;
//  font-size: 22px;
//  cursor: pointer;
//  color: #c4c7cd;
//}

.icon {
  margin-right: 5px;
  display: block;
  font-size: 22px;
  cursor: pointer;
  color: #c4c7cd;
  transition: color 0.3s;
}

.icon:hover {
  color: #409EFF;
  /* 悬停时的颜色 */
}

.active-icon {
  color: #409EFF;
  /* 当 showExtremeValue 为 true 时的颜色 */
}

.help {
  width: 24px;
  height: 32px;
  opacity: 0.5;
}

.p-30 {
  padding: 15px;
  box-sizing: border-box;
}

.status1-0 {
  color: #33bf09
}

.status1-1 {
  //font-weight: bold;
  color: #1296db
}

.status1-2 {
  //font-weight: bold;
  color: #ffbb36
}

.status1-3 {
  //font-weight: bold;
  color: #ff8000
}

.status1-4 {
  //font-weight: bold;
  color: #d81e06
}

.status1-5 {
  //font-weight: bold;
  color: #bfbfbf
}
</style>