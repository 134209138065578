<template>
  <div class="jTableBox" style="margin-top: 10px;">
    <div class="out-line">
      <div class="flex-start-row" v-if="tableData.length">
        <div class="b-g-c" v-for="(item, i) in tableData" :key="i">
          <div class="flex-l-c">
            <el-image style="width: 65px; height: 65px" :src="!['normal_aggregate', 'deep_displacement_aggregate'].includes(item.deviceTypeCode) ? (item.productPhotoFileId ?
              $FileBaseURL + item.productPhotoFileId + $setToken() :
              require(`@/assets/uiBack/${item.deviceState == 'online'
                ? 'on-line' : 'out-line'}.png`)) : require(`@/assets/uiBack/yldevice.png`)" fit="fit"></el-image>
            <div class="flex-c">
              <div class="flex-row" style="justify-content: flex-start;">
                <span v-if="item.warningStatus == '0' || item.warningStatus == '5'" class="status"
                  :class="{ active: item.deviceState == 'online' }">
                  {{ setLabel(item.deviceState, DEVICE_STATE) }}
                </span>
                <span v-else :class="'status warning-status-' + item.warningStatus">
                  {{ setLabel(item.warningStatus, WARNING_STATUS).slice(0, 2) }}
                </span>
                <span class="title" style=" width: calc(100% - 39px);">
                  <el-tooltip placement="right" :content="item.name">
                    <span class="ellipsis">{{ item.name }}</span>
                  </el-tooltip>
                </span>
              </div>
              <div v-loading="latestDataLoading" element-loading-spinner="el-icon-loading">
                <div v-if="latestDataMap && latestDataMap[item.id] && latestDataMap[item.id].length">
                  <div class="flex-row" style="justify-content: space-between;">
                    <div class="m-w-40 ellipsis" v-for="(cItem, i) in latestDataMap[item.id].slice(0, 2)" :key="i">
                      <el-tooltip placement="right">
                        <div slot="content" v-html="cItem.propertyName"></div>
                        <!--                        <span class="sub-title ellipsis">{{cItem.propertyName + cItem.propertyName + cItem.propertyName }}</span>-->
                        <span class="sub-title ellipsis">{{ cItem.propertyName }}</span>
                      </el-tooltip>

                      <span class="sub-content ellipsis" style="text-align: center">{{ cItem.formatValue || '-'
                        }}</span>
                    </div>
                    <div class="m-w-40" v-if="latestDataMap[item.id].length > 2">
                      <el-tooltip placement="right">
                        <div slot="content" v-html="setLatestData(latestDataMap[item.id]).str"></div>
                        <span class="sub-title el-icon-more"></span>
                      </el-tooltip>
                      <span class="sub-content ellipsis"> </span>
                    </div>
                  </div>
                  <span class="sub-title-2">更新时间:{{ setLatestData(latestDataMap[item.id]).time }}</span>
                </div>
                <div v-else>
                  <span class="sub-title">该设备无监测属性</span>
                  <span class="sub-title-2">更新时间:{{ item.syncTime }}</span>
                </div>
                <!-- <span class="status" :class="{ active: item.deviceState == 'online' }">
                {{ setLabel(item.deviceState, DEVICE_STATE) }}
            </span> -->
                <!--                <span class="sub-title-2">更新时间:{{ setLatestData(latestDataMap[item.id]).time }}</span>-->
              </div>
            </div>
          </div>
          <div class="btn-bottom">
            <el-button v-if="item.deviceTypeCode === 'video'" type="text" class="control iconfont-simyng iconfont-simyng-video2" @click="handlerBtn('监测数据', item)">
              播放
            </el-button>
            <el-button v-else type="text" class="control iconfont icon-jianceshuju" @click="handlerBtn('监测数据', item)">
              数据
            </el-button>
            <el-button type="text" class="control iconfont icon-shebeizhuangtai"
              :class="{ 'disabled': ['normal_aggregate', 'deep_displacement_aggregate', 'video'].includes(item.deviceTypeCode) }"
              :disabled="['normal_aggregate', 'deep_displacement_aggregate', 'video'].includes(item.deviceTypeCode)"
              @click="handlerBtn('设备状态', item)">
              状态
            </el-button>
            <!-- <el-button type="text" class="control iconfont icon-shebeixinxi"
              @click="handlerBtn('设备信息', item)">
              信息
            </el-button> -->
            <!-- <el-button type="text" class="control control-last iconfont icon-kaidan"
              @click="handlerBtn(otherBtn, item)">
              {{ otherBtn }}
            </el-button> -->
            <el-button type="text" class="control iconfont icon-zhiling"
              :class="{ 'disabled': ['normal_aggregate', 'deep_displacement_aggregate'].includes(item.deviceTypeCode) }"
              :disabled="['normal_aggregate', 'deep_displacement_aggregate'].includes(item.deviceTypeCode)"
              @click="handlerBtn('指令下发', item)">
              指令
            </el-button>
            <el-dropdown class="control" size="mini" @command="handlerBtn($event, item)">
              <span class="el-dropdown-link">
                <i class="control iconfont icon-gengduo"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="设备信息">设备信息</el-dropdown-item>
                <el-dropdown-item v-if="otherBtn" :command="otherBtn">{{ otherBtn }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
      <div v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
    </div>
    <jCheckBoxTable v-model="showCheckBoxTable" :paramsInfo="paramsInfo" :list="monitorList" ref="jCheckBoxTable"
      width="950px" />
    <div class="right">
      <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="page.pageNum" :page-size="page.pageSize" layout="total, prev, pager, next" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "jTableBox",
  props: {
    tableData: {
      type: Array,
      default: () => []
    },
    widthNum: {
      type: Number,
      default: 4
    },
    total: {
      type: Number,
      default: 40
    },
    otherBtn: {
      type: String,
      default: ''
    },
    latestDataLoading: {
      type: Boolean,
      default: true
    },
    latestDataMap: {
      type: Object,
      default: () => { }
    },
  },
  data() {
    return {
      page: {
        pageNum: 1,
        pageSize: 50
      },
      activeName: "",
      paramsInfo: {},
      showCheckBoxTable: false,
      monitorList: [],
      DEVICE_TYPE: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_TYPE,
      DEVICE_STATE: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.DEVICE_STATE,
      WARNING_STATUS: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS
    }
  },
  computed: {

  },
  beforeDestroy() {
  },
  methods: {
    setDeviceSN(name) {
      // return name
      return name ? name.slice(0, 10) : ""
    },
    closeDw() {
      this.$refs.jCheckBoxTable.closeDw()
    },
    setLatestData(list) {
      let str = [], time = "", i = 0
      for (let item of list) {
        str.push(item.propertyName + "：" + (item.formatValue || '-'))
        if (i === 0) {
          time = this.timestampToTime(item.timestamp)
        }
        i++
      }
      return { str: str.join('<br>'), time: time || '-' }
    },
    timestampToTime(timestamp) {
      if (!timestamp) return ""
      var date = new Date(timestamp);
      // 转为北京时间
      date.setTime(date.getTime() + 8 * 60 * 60 * 1000);
      var time = date.toISOString().replace(/T|\.\d{3}Z/g, ' ').trim();
      return time;
    },
    handlerBtn(key, row) {
      if (key == "指令下发") {
        if (!row.productId) {
          this.$message.error("该设备尚未绑定产品")
          return
        }
        this.paramsInfo = { listUrl: 'queryProductFunctionListPageV2', params: { productId: row.productId, deviceId: row.id, deviceCode: row.deviceCode } }
        this.showCheckBoxTable = true
      } else {
        this.$emit('handlerBtn', { row, key })
      }
    },
    setLabel(value, list, label) {
      let labels = (list || []).find(item => item.codeValue == value) || {}
      return labels.codeDesc || ''
    },
    handleSizeChange(val) {
      let obj = this.$store.state.searchable
      obj = Object.assign(obj, this.page)
      this.$emit('search', obj)
    },
    handleCurrentChange(val) {
      let obj = this.$store.state.searchable
      obj = Object.assign(obj, this.page)
      this.$emit('search', obj)
    },
  }
}
</script>

<style scoped lang="scss">
.out-line {
  box-sizing: border-box;
  padding: 0 5px;
}
.ellipsis{
  display: block;
  width:100%;
}
.disabled {
  background: #f2f2f2 !important;
  color: #9f9f9f !important;
}

.b-g-c {
  box-sizing: border-box;
  width: 280px;
  height: auto;
  margin-top: 10px;
  background: #ffffff;
  min-height: 130.8px;
  border-radius: 8px;
  box-shadow: 0 0 6px rgba(134, 157, 165, 0.27);
  padding: 10px 5px;
  overflow: hidden;
  margin-right: 10px;

  .flex-c {
    width: calc(100% - 80px);
    // margin-left: 15px;
    position: relative;
  }
}

.m-w-40 {
  flex-wrap: nowrap;
  overflow: hidden;
  max-width: calc(100% / 3);
  margin-right: 8px;
}

.title {
  line-height: normal;
  font-family: Alibaba PuHuiTi;
  font-weight: 700;
  color: #3e3e3e;
  font-size: 14px;
  display: inline-block;
}

.flex-row {
  width: 100%;
}

.sub-title {
  font-style: normal;
  line-height: 17px;
  font-family: Alibaba PuHuiTi;
  color: rgba(62, 62, 62, .5);
  font-size: 12px;
  margin-top: 6px;
  // margin-bottom: 3px;
  display: block;
}

.sub-title-2 {
  font-family: Alibaba PuHuiTi;
  font-size: 10px;
  color: rgba(62, 62, 62, .5);
  line-height: 10px;
  margin-top: 5px
}

.sub-content {
  height: 17px;
  line-height: 17px;
  font-family: Alibaba PuHuiTi;
  color: #000000;
  font-size: 12px;
  text-align: left;
}

.jTableBox {
  width: 100%;
  height: auto;
  overflow-x: scroll;
}

.right {
  text-align: right;
  width: 100%;
  margin-top: 20px;
}

span {
  display: block;
}

.status {
  width: 31px;
  height: 18px;
  background: #bcbcbc;
  border-radius: 3px;
  color: #ffffff;
  font-size: 10px;
  text-align: center;
  line-height: 18px;
  margin-right: 8px;
}

// .status::before {
//     content: '';
//     position: absolute;
//     left: 5px;
//     top: 50%;
//     transform: translate3d(0, -50%, 0);
//     width: 8px;
//     border-radius: 8px;
//     height: 8px;
//     background: $error;
// }

.status.active {
  background: #00cb00;
  font-family: Alibaba PuHuiTi;

}

::v-deep .el-tabs__item {
  padding: 0 10px;
  height: 30px;
  line-height: 30px;
}

.btn-bottom {
  margin-top: 15px;
  text-align: right;

  .el-button {
    background: #eff6ff;
    border-radius: 5px;
    font-size: 11px;
    padding: 4px 5px;
    margin-left: 3px;
    font-family: Alibaba PuHuiTi;
    color: #2859f3;
  }

  .el-button:first-child {
    margin-left: 0;
  }

  .el-button.control-last {
    background: #f2f2f2;
    color: #9f9f9f
  }
}

.iconfont .iconfont-simyng {
  font-size: 12px;
  font-weight: 700;
}

.iconfont-simyng-video2 {
  font-size: 16px;
}

p {
  margin: 0
}

.el-icon-more {
  cursor: pointer;
}

.warning-status-0 {
  background: rgb(26, 250, 41)
}

.warning-status-1 {
  background: rgb(18, 150, 219)
}

.warning-status-2 {
  background: rgb(212, 203, 0);
  //color: rgb(191, 191, 191)
}

.warning-status-3 {
  background: rgb(234, 149, 24)
}

.warning-status-4 {
  background: rgb(216, 30, 6)
}

.warning-status-5 {
  background: rgb(191, 191, 191)
}

::v-deep .el-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  //font-size: 24px; /* Increase the size of the loading spinner */
  color: #ffffff;
  /* White spinner color */
}
</style>