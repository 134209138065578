<template>
    <div class="select-group">
        <span v-for="(item, i) in tabs" :key="i" @click="tabClick(item, i)" :class="{ 'active': i == index }">
            <svg-icon class="svgIcon" :icon-class="item.icon"></svg-icon>
            {{ item.label }}
        </span>
    </div>
</template>
<script>
export default {
    name: "jCheckGroup",
    props: {
        value: [String, Array],
        tabs: [String, Array],
    },
    data() {
        return {
            index: 0
        }
    },
    methods: {
        tabClick(item, i) {
            this.index = i
            this.$emit('input', item.value)
            this.$emit('tab-click', item.value)
        },
    }
}
</script>
<style scoped lang="scss">
.select-group {
    box-sizing: border-box;
    width: 100%;
    padding:0 12px;
    height: 51px;
    background: #eff6ff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .svgIcon{
        width:20px;
        height:20px;
    }
    span {
        font-family: Alibaba PuHuiTi;
        color: #707070;
        font-size: 14px;
        padding: 0 10px;
        line-height: 32px;
        cursor: pointer;
        ::v-deep path{
            fill:#707070;
        }
        &.active {
            font-family: Alibaba PuHuiTi;
            color: #2859f3;
            font-size: 14px;
            background: #ffffff;
            border-radius: 4px;
            box-shadow: 0px 2px 2px rgba(127, 143, 194, 0.2);
        }
    }
}
.svg-icon{
    width: 20px;
    height: 20px;
}
</style>