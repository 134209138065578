export default {
  path: '/monitor',
  name: 'monitor',
  component: 'router-view.vue',
  redirect: { name: 'monitor_project_list' },
  meta: {
    isMenu: true,
    redirect: true,
    title: "监测点管理",
  },
  children: [
    {
      path: 'project',
      name: 'monitor_project',
      component: 'router-view.vue',
      redirect: { name: 'monitor_project_list' },
      meta: {
        isMenu: true,
        title: "项目管理",
      },
      children: [
        {
          path: 'list',
          name: 'monitor_project_list',
          component: 'monitor/project/index.vue',
          meta: {
            isMenu: false,
            title: "列表",
          }
        },
      ]
    },
    {
      path: 'monitorYl',
      name: 'monitor_yl',
      component: 'router-view.vue',
      redirect: { name: 'monitor_yl_list' },
      meta: {
        isMenu: true,
        title: "监测点雨量",
      },
      children: [
        {
          path: 'list',
          name: 'monitor_yl_list',
          component: 'monitor/yl/index.vue',
          meta: {
            isMenu: false,
            title: "列表",
          }
        },
      ]
    },
    {
      path: 'info',
      name: 'monitor_info',
      component: 'router-view.vue',
      redirect: { name: 'monitor_info_list' },
      meta: {
        isMenu: true,
        title: "监测点信息维护",
      },
      children: [
        {
          path: 'list',
          name: 'monitor_info_list',
          component: 'monitor/info/index.vue',
          meta: {
            isMenu: false,
            title: "列表",
          }
        },
        {
          path: 'detail',
          name: 'monitor_info_detail',
          component: 'monitor/info/form.vue',
          meta: {
            isMenu: false,
            title: "监测点信息详情",
          },
        },
      ]
    },
    {
      path: 'monitor-device',
      name: 'monitor_device',
      component: 'router-view.vue',
      redirect: { name: 'monitor_device_list' },
      meta: {
        isMenu: true,
        title: "监测点数据",
      },
      children: [
        {
          path: 'list',
          name: 'monitor_device_list',
          component: 'warning/monitor/monitorDevice.vue',
          meta: {
            isMenu: false,
            title: "列表",
          }
        },
        {
          path: 'monitor-data-detail',
          name: 'monitor_data_detail',
          component: 'warning/monitor/components/form.vue',
          meta: {
            isMenu: false,
            title: "监测点数据详情",
          },
        },
      ]
    },

    // {
    //   path: 'data',
    //   name: 'MONITOR',
    //   component: 'router-view.vue',
    //   redirect: '/monitor/data/list',
    //   meta: {
    //     isMenu: true,
    //     title: "监测点数据",
    //   },
    //   children: [
    //     {
    //       path: 'list',
    //       name: 'monitor_data_list',
    //       component: 'monitor/data/index.vue',
    //       meta: {
    //         isMenu: false,
    //         title: "列表",
    //       }
    //     },
    //   ]
    // },
  ]
}