import { post } from "@/request/http";

export default {
    queryDeviceListPage(param, msg) { //设备列表分页
        return post("/device/device/queryDeviceListPage/v2", param, msg)
    },
    queryNormalDeviceListPage(param, msg) { //子设备列表分页
        return post("/device/device/query/normal-device-info/page", param, msg)
    },
    queryDeviceInfoPage(param, msg) { //设备列表分页
        return post("/device/device/query/device-info/page", param, msg)
    },
    queryDeviceDataPage(param, msg) { //设备列表分页
        return post("/device/device/query/device-data/page", param, msg)
    },
    gatewayDeviceList(param, msg) { //全量查询网关设备
        return post("/device/device/query/gateway-device-list", param, msg)
    },
    queryDeviceListPageLatest(param, msg) { //设备列表分页
        return post("/device/device/queryDeviceListPage/latest", param, msg)
    },
    saveDevice(param, msg) { //保存设备信息
        return post("/device/device/saveDevice/v2", param, msg)
    },
    saveVideoDevice(param, msg) { //保存设备信息
        return post("/device/device/video/save-device", param, msg)
    },
    deleteDevice(param, msg) { //删除设备信息
        return post("/device/device/deleteDevice", param, msg)
    },
    deleteDevicesBatch(param, msg) { //删除设备信息
        return post("/device/device/deleteDevices", param, msg)
    },
    queryProductListPage(param, msg) { //产品列表分页
        return post("/device/product/queryProductListPage", param, msg)
    },
    queryProductList(param, msg) { //产品列表不分页
        return post("/device/product/queryProductList", param, msg)
    },
    queryProductById(param, msg) { //单个产品详情
        return post("/device/product/queryProductById", param, msg)
    },
    queryProductPropertyListPage(param, msg) { //产品属性列表分页
        return post("/device/product/queryProductPropertyListPage", param, msg)
    },
    queryProductFunctionListPage(param, msg) { //产品功能列表分页
        return post("/device/product/queryProductFunctionListPage", param, msg)
    },
    queryProductEventListPage(param, msg) { //产品事件列表分页
        return post("/device/product/queryProductEventListPage", param, msg)
    },
    queryProductTagListPage(param, msg) { //产品标签列表分页
        return post("/device/product/queryProductTagListPage", param, msg)
    },
    updateProductEvent(param, msg) { //更新产品事件
        return post("/device/product/updateProductEvent", param, msg)
    },
    updateProductProperty(param, msg) { //更新产品属性
        return post("/device/product/updateProductProperty", param, msg)
    },
    updateProductPropertyBatch(param, msg) { //批量更新产品属性
        return post("/device/product/updateProductProperty/batch", param, msg)
    },
    resetProductPropertyBatch(param, msg) { //批量更新产品属性
        return post("/device/product/resetProductProperty", param, msg)
    },
    updateProductFunction(param, msg) { //更新产品功能
        return post("/device/product/updateProductFunction", param, msg)
    },
    updateProductTag(param, msg) { //更新产品标签
        return post("/device/product/updateProductTag", param, msg)
    },
    syncProduct(param, msg) { //同步产品
        return post("/device/product/syncProduct", param, msg)
    },
    syncProductById(param, msg) { //同步单个产品
        return post("/device/product/syncProductById", param, msg)
    },
    getProductTsl(param, msg) { //导出 tsl
        return post("/device/product/getProductTsl", param, msg)
    },
    saveProductType(param, msg) { //导出 tsl
        return post("/device/product/saveProductType", param, msg)
        // return post("/device/product/saveDeviceType",param,msg)
    },
    saveDeviceRemark(param, msg) { //保存设备备注
        return post("/device/device/saveDeviceRemark", param, msg)
    },
    queryDevicePropertiesData(param, msg) { //获取设备属性数据
        return post("/device/device/queryDevicePropertiesDataMultiple", param, msg)
    },
    queryDevicePropertiesDataPage(param, msg) { //获取设备属性数据
        return post("/device/device/queryDevicePropertiesDataPage", param, msg)
    },
    syncDevice(param, msg) { //同步设备
        return post("/device/device/syncDevice", param, msg)
    },
    queryAccessProviderList(param, msg) { //获取产品接入类型列表
        return post("/device/product/queryAccessProviderList", param, msg)
    },
    // wsq 首页管理查询设备数据接口
    queryHomepageInfo(param, msg) { //获取产品接入类型列表
        return post("/device/device/query/homepage-info", param, msg)
    },
    deleteManufacturerBatch(param, msg) { //批量删除
        return post("/device/manufacturer/delete/batch", param, msg)
    },
    statisticsDeviceStatus(param, msg) { //设备状态统计
        return post("/device/device/statistics/DeviceStatus", param, msg)
    },
    queryDeviceById(param, msg) { //设备id
        return post("/device/device/query/id/v2", param, msg)
    },
    updateProductIcon(param, msg) { //上传ICON
        return post("/device/product/update-product-icon", param, msg)
    },
    resetWarning(param, msg) { //重置
        return post("/device/device/reset-warning", param, msg)
    },
    resetWarningForMonitor(param, msg) { //重置
        return post("/device/monitor/reset-warning", param, msg)
    },
    addAggregateDevice(param, msg) { //新增聚合设备
        return post("/device/device/add/aggregate-device/v2", param, msg)
        // return post("/device/device/add/aggregate-device", param, msg)
    },
    updateAggregateDevice(param, msg) { //修改聚合设备
        return post("/device/device/update/aggregate-device/v2", param, msg)
        // return post("/device/device/update/aggregate-device", param, msg)
    },
    delAggregateDevice(param, msg) { //修改聚合设备
        return post("/device/device/del/aggregate-device", param, msg)
    },
    maxOfflineTime(param, msg) { //设置低功耗设备的最大离线时长
        return post("/device/product/set/max-offline-time", param, msg)
    },
    lowpowerdevice(param, msg) { //设置产品是否是低功耗设备
        return post("/device/product/set/low-power-device", param, msg)
    },
    getImage(param, msg) { //获取设备图片列表
        return post("/device/device/get-image", param, msg)
    },
    getImagePage(param, msg) { //获取设备图片列表-fenye
        return post("/device/device/get-image/page", param, msg)
    },
    saveProductTypeBatch(param, msg) { //获取设备图片列表-fenye
        return post("/device/product/save/product-type/batch", param, msg)
        // /device/product/save/product-type/batch
    },
    queryDeviceByCode(param, msg) { //获取设备图片列表-fenye
        return post("/device/device/query/device-type-code/page", param, msg)
    },
    // 深部位移曲线图分页查询
    queryDeepDisplacementDeviceChartDataPage(param, msg) { //获取设备属性数据
        return post("/device/device/depth-distance-data/query/page", param, msg)
    },
    // 深部位移曲线图查询
    queryDeepDisplacementDeviceChartData(param, msg) { //获取设备属性数据
        return post("/device/device/depth-distance-data/query", param, msg)
    },
    // 深部位移曲线图查询
    editAlias(param, msg) { //获取设备属性数据
        return post("/device/function/input/config/edit/alias", param, msg)
    },
    //查询属性分组名称列表
    propertyGroupList(param, msg) {
        return post("/device/device/query/product-property/group-name", param, msg)
    },
    //查询属性分组名称列表
    propertyGroupInfo(param, msg) {
        return post("/device/product/query/product-property/group-info", param, msg)
    },
    //查询设备属性分组的属性数据
    propertyGroupData(param, msg) {
        return post("/device/device/query/product-property-group/data", param, msg)
    },
    //查询设备属性分组的属性数据
    setPropertyGroupName(param, msg) {
        return post("/device/product/set/property-group", param, msg)
    },
     //批量隐藏
     functionHideBatch(param, msg) {
        return post("/device/product/set/function/hide/batch", param, msg)
    },
    functionHide(param, msg) {
        return post("/device/product/set/function/hide", param, msg)
    },
    //// 视频设备
    // 分页查询通道信息
    postQueryDeviceVideoChannelPage(param, msg) {
        return post("/device/device/video/channel/query/page", param, msg)
    },
    // 全量查询通道信息
    postQueryDeviceVideoChannel(param, msg) {
        return post("/device/device/video/channel/query", param, msg)
    },
    // 播放视频
    postDevicePlayVideo(param, msg) {
        return post("/device/device/video/play", param, msg)
    },

    // 监测点视频设备
    // 全量查询通道信息
    postQueryMonitorVideoChannel(param, msg) {
        return post("/device/device/video/query-by-monitor", param, msg)
    },

}
