import { post } from "@/request/http";

export default {
    /////////////////////// 设备预警相关接口 ////////////////////////////
    // 分页查询
    postQueryDeviceWarningPage(param,msg) {
        return post("/device/event/device-warning/query/page",param,msg)
    },
    // 单个处理
    postProcessDeviceWarning(param,msg) {
        return post("/device/event/device-warning/process",param,msg)
    },
    // 批量处理
    postProcessDeviceWarningBatch(param,msg) {
        return post("/device/event/device-warning/process/batch",param,msg)
    },
    // 单个忽略
    postIgnoreDeviceWarning(param,msg) {
        return post("/device/event/device-warning/ignore",param,msg)
    },
    // 批量忽略
    postIgnoreDeviceWarningBatch(param,msg) {
        return post("/device/event/device-warning/ignore/batch",param,msg)
    },
    // 单个转异常
    postDeviceWarning2Error(param,msg) {
        return post("/device/event/device-warning/to-error",param,msg)
    },
    // 单个删除
    postDelProcessedDeviceWarning(param,msg) {
        return post("/device/event/device-warning/delete",param,msg)
    },
    // 批量转异常
    postDeviceWarning2ErrorBatch(param,msg) {
        return post("/device/event/device-warning/to-error/batch",param,msg)
    },
    // 备注
    postSetDeviceWarningRemark(param,msg) {
      return post("/device/event/device-warning/remark",param,msg)
    },
    // 批量导出
    postExportDeviceWarningBatch(param,msg) {
        return post("/device/event/device-warning/export/excel",param,msg)
    },




    ///////////////////////////// 监测点预警相关接口 ///////////////////////////////
    // 分页查询
    postQueryMonitorWarningPage(param,msg) { // 分页查询监测点预警信息
        return post("/device/event/monitor-warning/query/page",param,msg)
    },
    // 单个处理
    postProcessMonitorWarning(param,msg) {
        return post("/device/event/monitor-warning/process",param,msg)
    },
    // 批量处理
    postProcessMonitorWarningBatch(param,msg) {
        return post("/device/event/monitor-warning/process/batch",param,msg)
    },
    // 单个忽略
    postIgnoreMonitorWarning(param,msg) {
        return post("/device/event/monitor-warning/ignore",param,msg)
    },
    // 批量忽略
    postIgnoreMonitorWarningBatch(param,msg) {
        return post("/device/event/monitor-warning/ignore/batch",param,msg)
    },
    // 单个删除
    postDelProcessedMonitorWarning(param,msg) {
        return post("/device/event/monitor-warning/delete",param,msg)
    },
    // 备注
    postSetMonitorWarningRemark(param,msg) {
        return post("/device/event/monitor-warning/remark",param,msg)
    },
    // 批量导出
    postExportMonitorWarningBatch(param,msg) {
        return post("/device/event/monitor-warning/export/excel",param,msg)
    },
    postQueryMonitorWarningDeviceList(param,msg) {
        return post("/device/event/monitor-warning/query/detail",param,msg)
    },



    /////////////////////////// 设备异常 ////////////////////////////////
    // 分页查询
    postQueryDeviceErrorPage(param,msg) {
        return post("/device/event/error/query/page",param,msg)
    },
    // 单个忽略
    postIgnoreDeviceError(param,msg) {
        return post("/device/event/error/ignore",param,msg)
    },
    // 批量忽略
    postIgnoreDeviceErrorBatch(param,msg) {
        return post("/device/event/error/ignore/batch",param,msg)
    },
    // 单个处理
    postProcessDeviceError(param,msg) {
        return post("/device/event/error/process",param,msg)
    },
    // 批量处理
    postProcessDeviceErrorBatch(param,msg) {
        return post("/device/event/error/process/batch",param,msg)
    },
    // 单个删除
    postDelProcessedDeviceError(param,msg) {
        return post("/device/event/error/delete",param,msg)
    },
    // 批量删除
    postDelProcessedDeviceErrorBatch(param,msg) {
        return post("/device/event/error/delete/batch",param,msg)
    },
    // 批量导出
    postExportDeviceErrorBatch(param,msg) {
        return post("/device/event/error/export/excel",param,msg)
    },
    // 备注
    postSetDeviceErrorRemark(param,msg) {
        return post("/device/event/error/remark",param,msg)
    },
}