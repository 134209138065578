<template>
    <el-dialog :title="title" :visible.sync="show" :width="width" @close="cancle" :modal="false">
        <template>
            <!-- <jTitle title="批量下发" :disabled="checkList.length < 2" @click="handlerBtn('批量下发', {})" type="btn" /> -->
            <jTable v-model="columnConfig" :tableData="tableData" :total="total" :showHeader="false" @search="search"
                @selection="selection" @selectAll="selectAll" :selection="false" :checkList="checkList"
                :controlWidth="'300px'">
                <template v-slot:control="scope">
                    <span class="control" @click="handlerBtn('批量下发', scope.row)">
                        <svg-icon class="svgIcon" icon-class="zhilingxiafa"></svg-icon>
                        指令下发</span>
                    <span class="control" @click="handlerBtn('执行情况', scope.row)">
                        <svg-icon class="svgIcon" icon-class="zhixingqingkuang"></svg-icon>
                        执行情况</span>
                </template>
            </jTable>
        </template>
        <el-drawer :title="dwInfo.title" :visible.sync="dwInfo.show"
            :class="{ 'wd-550': dwInfo.title == '指令下发', 'wd-700': dwInfo.title == '执行情况' }"
            :modal-append-to-body="false">
            <template v-if="dwInfo.title == '指令下发'">
                <el-form class="app-search-form" label-width="0px" :model="dwInfo" ref="dwForm">
                    <el-table ref="jTable" class="zl-table" :data="dwInfo.inputsList" style="max-height: 75vh;"
                        :header-cell-style="{ 'text-align': 'center', 'background': '#ededed' }">
                        <el-table-column label="输入参数" align="left" show-overflow-tooltip width="130px">
                            <template slot-scope="{row}">
                                <span><svg-icon class="svgIcon" icon-class="srcs"></svg-icon><span v-show="row.required == 1" :class="{require:row.required == 1}">*</span>{{ row.alias||row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="输入类型" align="center" show-overflow-tooltip width="140px">
                            <template slot-scope="{row}">
                                <span>{{ setLableTypeDesc(row.dataTypeCode) }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="值" align="left" min-width="200px">
                            <template slot-scope="scope">
                                <el-form-item class="app-search-item"
                                    :prop="'inputsList.' + scope.$index + '.' + scope.row.sign"
                                    :rules="[{ required: scope.row.required == 1, message: scope.row.name + '值必填', trigger: 'change' }]">
                                    <el-radio-group class="tag-title" v-if="scope.row.dataTypeCode == 'boolean'"
                                        v-model="scope.row[scope.row.sign]" size="mini">
                                        <el-radio :label="scope.row.validRuleObj.trueValue">{{
                                            scope.row.validRuleObj.trueText }}</el-radio>
                                        <el-radio :label="scope.row.validRuleObj.falseValue">{{
                                            scope.row.validRuleObj.falseText }}</el-radio>
                                    </el-radio-group>
                                    <el-select clearable filterable v-model="scope.row[scope.row.sign]"
                                        placeholder="请选择" v-else-if="scope.row.dataTypeCode == 'enum'">
                                        <el-option v-for="item in (scope.row.validRuleObj?.elements) || []"
                                            :key="item.value" :label="item.text" :value="item.value">
                                        </el-option>
                                    </el-select>
                                    <el-input v-model="scope.row[scope.row.sign]" v-else
                                        @blur="setValue($event, scope.row)" />
                                </el-form-item>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-form>
                <div class="demo-drawer__footer">
                    <el-button @click="dwInfo.show = false">关 闭</el-button>
                    <el-button type="primary" @click="submit('批量下发')">执行</el-button>
                </div>
            </template>
            <template v-else-if="dwInfo.title == '执行情况'">
                <jTable v-model="table2.columnConfig" :tableData="table2.tableData" :total="table2.total" controlName=""
                    @search="search2" style="height: 75vh"></jTable>
            </template>
        </el-drawer>
    </el-dialog>
</template>
<script>

export default {
    name: "jCheckBoxTable",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "70%"
        },
        span: {
            type: Number,
            default: 24
        },
        paramsInfo: {
            type: Object,
            default: () => {
                return {
                    listUrl: "queryProductFunctionListPageV2",
                    params: {}
                }
            }
        },
        list: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: "设备指令"
        },
        baseForm: {
            type: Object,
            default: () => {
                return {
                    monitorPointNum: { key: 'monitorPointNum', span: 8, label: "监测点编号", labelWidth: "85px", type: 'input', placeholder: "请输入" },
                    monitorPointName: { key: 'monitorPointName', span: 8, label: "监测点", type: 'input', placeholder: "请输入" },
                    monitorProjectName: { key: 'monitorProjectName', span: 8, label: "项目", type: 'input', placeholder: "请输入" },
                    countyCode: { key: 'countyCode', span: 8, label: "行政区划", type: 'area', placeholder: "请选择" },
                }
            }
        },
        columnConfig: {
            type: Array,
            default: () => {
                return [
                    { label: "指令别称", prop: "alias", svgIcon: "zhilingLg" },
                    { label: "下发状态", prop: "async", alias: "asyncNm", color: true },
                ]
            }
        },
    },
    data() {
        return {
            tableData: [],
            total: 0,
            table2: {
                tableData: [],
                columnConfig: [
                    { label: "执行状态", prop: "statusCode", color2: true },
                    { label: "下发时间", prop: "updateTime" },
                    { label: "执行时间", prop: "timestamp" },
                    { label: "执行结果", prop: "result" },
                ],
                total: 0,
            },
            show: false,
            dwInfo: {
                show: false,
                inputsList: []
            },
            checkList: [],
        };
    },
    watch: {
        value: {
            handler(val) {
                this.show = val
                val && this.queryList({ pageNum: 1, pageSize: 50 })
            },
            immediate: true,
        },
        list: {
            handler(val) {
                this.checkList = JSON.parse(JSON.stringify(val))
            },
            immediate: true,
        },

    },
    mounted() {
        // this.queryList({ pageNum: 1, pageSize: 50 })
    },
    methods: {
        setLableTypeDesc(value) {
            let info = [
                { codeDesc: "整数型", codeValue: 'int' },
                { codeDesc: "数组", codeValue: 'array' },
                { codeDesc: "布尔型", codeValue: 'boolean' },
                { codeDesc: "时间型", codeValue: 'date' },
                { codeDesc: "双精度浮点型", codeValue: 'double' },
                { codeDesc: "枚举", codeValue: 'enum' },
                { codeDesc: "文件", codeValue: 'file' },
                { codeDesc: "单精度浮点型", codeValue: 'float' },
                { codeDesc: "地理位置", codeValue: 'geoPoint' },
                { codeDesc: "长整数型", codeValue: 'long' },
                { codeDesc: "结构体", codeValue: 'object' },
                { codeDesc: "密码", codeValue: 'password' },
                { codeDesc: "字符串", codeValue: 'text' },
                { codeDesc: "字符串", codeValue: 'string' },
            ].find(item => value == item.codeValue)
            return info?.codeDesc || ""
        },
        closeDw() {
            this.dwInfo.show = false
        },
        setValue(e, row) {
            switch (row.dataTypeCode) {
                case "int":
                case "long":
                    if (/^\d+$/.test(row[row.sign])) {
                        this.$set(row, row.sign, (row[row.sign] * 1).toFixed(0) + "")
                    } else {
                        this.$set(row, row.sign, "")
                    }
                    break;
                case "float":
                    if (/^\d+$/.test(row[row.sign])) {
                        this.$set(row, row.sign, (row[row.sign] * 1).toFixed(6) + "")
                    } else {
                        this.$set(row, row.sign, "")
                    }
                    break;
                case "double":
                    if (/^\d+$/.test(row[row.sign])) {
                        this.$set(row, row.sign, (row[row.sign] * 1).toFixed(12) + "")
                    } else {
                        this.$set(row, row.sign, "")
                    }
                    break;
            }
            console.log(e, row)
        },
        cancle() {
            this.show = false
            this.checkList = JSON.parse(JSON.stringify(this.list))
            this.$emit('input', false)
        },
        search(formData) {
            this.queryList(formData)
        },
        search2(formData) {
            this.queryList2(formData)
        },
        async queryList(resultData) {
            let formData = resultData
            delete formData.name
            let params = {
                entity: { ...formData, ...this.paramsInfo.params } || {},
                pageSize: formData.pageSize,
                pageNum: formData.pageNum
            }
            delete params.entity.pageSize
            delete params.entity.pageNum
            let res = await this.$api[this.paramsInfo.listUrl](params)
            if (res) {
                this.tableData = (res.list || res.resultList || res || []).map(item => {
                    item.alias = item.alias || item.name
                    return item
                })
                this.total = res.total || res.totalNum || 0
            }
        },
        async queryList2(formData) {
            let res = await this.$api.functionLog({
                entity: {
                    functionId: this.dwInfo.id,
                    deviceCode: this.paramsInfo?.params?.deviceCode
                },
                pageSize: formData.pageSize,
                pageNum: formData.pageNum
            })
            this.table2.tableData = (res.resultList || [])
            // .map(item => {
            //     item.timestamp = this.$tool.setFormatTime(item.timestamp)
            //     return item
            // })
            this.table2.total = res.totalNum
        },
        selection(row) {
            let ids = this.checkList.map(item => item.id)
            if (ids.includes(row.id)) {
                this.checkList = this.checkList.filter(item => item.id != row.id)
            } else {
                this.checkList.push(row)
            }
        },
        selectAll(obj) {
            if (obj.check) {
                let ids = this.checkList.map(item => item.id)
                for (let item of obj.tableData) {
                    !ids.includes(item.id) && this.checkList.push(item)
                }
            } else {
                let ids = obj.tableData.map(item => item.id)
                this.checkList = this.checkList.filter(item => !ids.includes(item.id))
            }
        },
        async handlerBtn(btnNm, rowData) {
            if (btnNm == "批量下发") {
                // let inputsList = (rowData?.inputs?JSON.parse(rowData.inputs): [])
                let inputsList = await this.$api.queryFunctionById({ entity: { functionId: rowData.id } })
                inputsList = (inputsList || []).map((item, index) => {
                    if (item.sign) {
                        this.$set(item, item.sign, '')
                    } else {
                        this.$set(item, item.param + index + '', '')
                    }
                    return item
                })
                this.dwInfo = {
                    show: true,
                    title: "指令下发",
                    inputsList
                }
                this.checkList = [rowData]
                 if(this.$refs.dwForm){
                    this.$refs.dwForm.clearValidate()
                }else{
                    this.$nextTick(()=>{
                        this.$refs.dwForm.clearValidate()
                    })
                }
            } else if (btnNm == "执行情况") {
                this.dwInfo = {
                    show: true,
                    id: rowData.id,
                    title: "执行情况",
                    inputsList:[]
                }
                this.queryList2({ pageNum: 1, pageSize: 50 })
            }
        },
        submit() {
            this.$refs.dwForm.validate(valid => {
                if (valid) {
                    // if (!this.dwInfo?.inputsList?.length) {
                    //     this.$message.error("未设置指令")
                    //     return
                    // }
                    let inputParam = {}
                    this.dwInfo.inputsList.map((item, index) => {
                        if (item[item.sign]) {
                            inputParam[item.sign] = item[item.sign]
                        }
                    })
                    this.$api.functionCall({ entity: { functionId: this.checkList[0].id, deviceCode: this.paramsInfo?.params?.deviceCode, inputParam } }, '下发成功').then(res => {
                        if (res) {
                            this.closeDw()
                        }
                    })
                } else {
                    this.$message.error("必填项未填写完全，请核查")
                }
            })
        }
    },
};
</script>
<style scoped lang="scss">
::v-deep .el-alert--info.is-light {
    background: rgba(244, 244, 245, .3);
}
.require{
    color: #F56C6C;
}
.control {
    margin: 0 15px;
}

::v-deep .el-drawer {

    .j-table,
    .zl-table {
        width: 94% !important;
        margin-left: 3%;
    }

    .el-table__body-wrapper {
        max-height: 70vh !important;
    }
}

.wd-550 ::v-deep .el-drawer {
    min-width: 550px !important;
}

.wd-700 ::v-deep .el-drawer {
    min-width: 700px !important;
}

.icon-zhiling-01 {
    font-size: 16px
}

.demo-drawer__footer {
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.tips {
    font-family: "Microsoft YaHei", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: $error;
    line-height: 22px;
}

.el-input-number {
    position: relative;
    width: 100%;

    ::before {
        content: '人';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $border;
    }
}

.fileName {
    color: $primary;
    font-size: 12px;
    cursor: pointer;
}

.down-load {
    ::v-deep .el-form-item__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0px !important;
    }
}

::v-deep .el-table__body-wrapper {
    overflow: auto;
    position: relative;
    max-height: 400px;
}

::v-deep .el-dialog {
    margin-top: 5vh !important;
}
</style>