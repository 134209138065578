<template>
    <div>
        <jSearch :baseForm="baseForm" @search="search" labelWidth="70px" :span1="8" :span="8">
            <template v-slot:control="scope">
                <div class="flex-wrap-start" v-if="!generalDevice">
                    <el-tag type="info">包含设备:{{ totalInfo.total || 0 }}</el-tag>
                    <el-tag type="success">在线:{{ totalInfo.online || 0 }}</el-tag>
                    <el-tag type="danger">离线:{{ totalInfo.offline || 0 }}</el-tag>
                    <el-tag type="danger">禁用:{{ totalInfo.notActive || 0 }}</el-tag>
                </div>
            </template>
        </jSearch>
        <jTable v-model="columnConfig" ref="jTable" :checkList="checkList" height="350" :tableData="tableData" :total="total"
            @search="search" @selection="selection" @selectAll="selectAll" :selection="true" controlName="">
        </jTable>
    </div>
</template>
<script>
export default {
    name: "jBindDevice",
    props: {
        value: {
            type: Array,
            default: () => []
        },
        params: {
            type: Object,
            default: () => {}
        },
        generalDevice: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            baseForm: {
                deviceTypeCode: { key: 'deviceTypeCode', span: 6,label:"设备类型", alias: "", type: 'select', placeholder: "请选择" },
                name: { key: 'name', span: 6, label: "设备名称", type: 'input', placeholder: "请输入" },
                productId: { key: 'productId', span: 6, label: "所属产品", type: 'select', placeholder: "请选择" },
                deviceState: { key: 'deviceState', span: 6, label: "设备状态", type: 'select', placeholder: "请选择" },
            },
            columnConfig: [
                { label: "设备名称", prop: "name", width: 180 },
                { label: "设备编号", prop: "deviceCode", width: 120 },
                { label: "设备类型", prop: "deviceTypeCode",color:true },
                { label: "所属产品", prop: "productName", width: 180 },
                { label: "设备状态", prop: "deviceState", color: true, width: 120 },
            ],
            totalInfo: {
                online: 0,
                offline: 0,
                total:0,
                notActive:0
            },
            tableData: [],
            total: 50,
            checkList: [],
            apiLoading: false
        };
    },
    watch: {
        "$store.state.dlShow": {
            handler() {
                if (this.$store.state.dlShow) {
                    if(this.generalDevice){
                        this.baseForm.deviceTypeCode.alias="普通设备类型"
                    }
                    this.$refs.jTable&&(this.$refs.jTable.page={pageNum: 1,pageSize:50})
                    this.queryList({ pageNum: 1, pageSize: 50 })
                }
            },
            immediate: true,
        }
    },
    mounted() {
        this.$refs.jTable&&(this.$refs.jTable.page={pageNum: 1,pageSize:50})
        this.queryList({ pageNum: 1, pageSize: 50 })
        this.statisticsDeviceStatus()
    },
    methods: {
        statisticsDeviceStatus() {
            let params = {

            }
            this.$api.statisticsDeviceStatus(params).then(res => {
                if (Array.isArray(res)) {
                  this.totalInfo.offline = res.filter(item => item.deviceState == 'offline')[0].num
                  this.totalInfo.online = res.filter(item => item.deviceState == 'online')[0].num
                  this.totalInfo.notActive = res.filter(item => item.deviceState == 'notActive')[0].num
                  this.totalInfo.total = res.filter(item => item.deviceState == 'all')[0].num
                }

            })
        },
        search(formData) {
            if (formData.bindRoleInfos) {
                formData.bindRoleInfos = [{ id: formData.orgNum }]
            } else {
                formData.bindRoleInfos = []
            }
            this.queryList(formData)
        },
        async queryList(formData) {
            if (this.apiLoading) return
            this.apiLoading = true
            let params = {
                entity: formData || {},
                pageSize: formData.pageSize,
                pageNum: formData.pageNum
            }
            delete params.entity.pageSize
            delete params.entity.pageNum
            if(this.params?.monitorInfoId){
                params.entity.monitorInfoId = this.params.monitorInfoId
            }
            let res = this.generalDevice?await this.$api.queryMonitorDevice(params):await this.$api.queryDeviceListPage(params)
            if (res) {
                this.tableData = (res.resultList || [])
                this.checkList = this.value
                this.total = res.totalNum
                this.apiLoading = false
            }
        },
        selection(row) {
            let ids = this.checkList.map(item => item.id)
            if (ids.includes(row.id)) {
                this.checkList = this.checkList.filter(item => item.id != row.id)
            } else {
                this.checkList.push(row)
            }
        },
        getCheckList() {
            return this.checkList
        },
        selectAll(obj) {
            if (obj.check) {
                let ids = this.checkList.map(item => item.id)
                for (let item of obj.tableData) {
                    !ids.includes(item.id) && this.checkList.push(item)
                }
            } else {
                let ids = obj.tableData.map(item => item.id)
                this.checkList = this.checkList.filter(item => !ids.includes(item.id))
            }
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep .el-alert--info.is-light {
    background: rgba(244, 244, 245, .3);
}

.tips {
    font-family: "Microsoft YaHei", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: $error;
    line-height: 22px;
}

.el-input-number {
    position: relative;
    width: 100%;

    ::before {
        content: '人';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $border;
    }
}

.fileName {
    color: $primary;
    font-size: 12px;
    cursor: pointer;
}

.down-load {
    ::v-deep .el-form-item__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0px !important;
    }
}

// ::v-deep .el-form-item__content .el-tag {
//     line-height: inherit;
// }

::v-deep .el-tag {
    margin-right: 5px;
}
</style>
