<template>
  <div class="h-100 w-100 p-30" style="position: relative; height: 50vh">
    <div style="cursor: move;">
      <div v-drag="{ _this: index, isFullDl: detailData.isFullDl, v: this }" style="cursor: move;" class="flex-row">
        <span class="dl-title" v-if="!deviceDataPage">
          <el-tag size="mini" style="margin-right: 5px; margin-top: 5px" effect="plain"
                  @click="onCopy(detailData.monitorName, '监测点名称 复制成功!')">{{ detailData.monitorName }}</el-tag>
          <el-tag type="warning" style="margin-right: 5px; margin-top: 5px" effect="plain" size="mini"
                  @click="onCopy(detailData.deviceCode, '设备编号 复制成功!')">{{ detailData.deviceCode }}</el-tag>
          <el-tag type="warning" style="margin-right: 5px; margin-top: 5px" effect="plain" size="mini"
                  @click="onCopy(detailData.deviceName, '设备名称 复制成功!')">{{ detailData.deviceName }}</el-tag>
          <el-tag :color="getStatesLabel('warningStatus', detailData.warningStatus).color"
                  style="margin-right: 5px; margin-top: 5px; color: white" size="mini" v-if="detailData.warningStatus">
            {{ getStatesLabel('warningStatus', detailData.warningStatus).label }}</el-tag>
        </span>
        <div class="flex-row" :class="{'flex-r':deviceDataPage}">
          <div class="icon el-icon-circle-close" title="关闭" @click="close"></div>
        </div>
      </div>
    </div>
    <div class="video" ref="videoContainerFather" v-loading="videoLoading">
      <el-empty v-show="!isShowVideo" class="h-100 w-100" description="" v-loading="videoLoading"></el-empty>
      <div v-show="isShowVideo" ref="videoContainer">
        <iframe v-if="!videoLoading" :src="videoUrl" style="width: 100%; height: 100%" :allowfullscreen="true"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'deviceVideoViewer',
  props: {
    detailData: {
      type: Object,
      default: () => { }
    },
    deviceDataPage: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      videoChannelList: [],
      checkedChannel: undefined,
      isShowVideo: false,
      videoUrl: undefined,
      videoLoading: false,
      videoWidth: undefined,
      videoHeight: undefined,
    }
  },
  // 自定义指令 —— 拖动div
  directives: {
    drag(el, info) {
      info.value.v.$nextTick(() => {
        let oDiv = el // 当前元素
        document.onselectstart = function () {
          return false
        }
        let prentDiv = document.getElementsByClassName('echartWarp' + info.value._this)[0]
        oDiv.onmousedown = function (e) {
          // 鼠标按下，计算当前元素距离可视区的距离
          let disX = e.clientX - prentDiv.offsetLeft
          let disY = e.clientY - prentDiv.offsetTop
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            let l = e.clientX - disX
            let t = e.clientY - disY
            // 移动当前元素
            if (!info.value.isFullDl) {
              prentDiv.style.left = l + 'px'
              prentDiv.style.top = t + 'px'
            }
          }
          document.onmouseup = function (e) {
            document.onmousemove = null
            document.onmouseup = null
            document.onselectstart = function () {
              return true
            }
          }
          return false
        }
      })
    }
  },
  mounted() {
    this.playVideo()
  },
  watch: {
  },
  destroyed() {
  },
  methods: {
    updatePlayerDomSize() {
      if (this.videoWidth && this.videoHeight) {
        // 父组件
        let fatherDom = this.$refs.videoContainerFather;
        let fatherWidth = fatherDom.clientWidth;
        let fatherHeight = fatherDom.clientHeight;
        // 根据宽度计算子组件高度
        let dom = this.$refs.videoContainer;
        let width = fatherWidth
        let height = (this.videoHeight / this.videoWidth) * width
        console.log('根据width计算height width-height: ', width + '-' + height)
        // 根据计算的高度判断是否超高
        if (height > fatherHeight) {
          height = fatherHeight
          width = (this.videoWidth / this.videoHeight) * height
        }
        console.log('根据height计算width width-height: ', width + '-' + height)

        if (width > 0 && height > 0) {
          dom.style.width = width + 'px';
          dom.style.height = height + "px";
          dom.style.paddingTop = 0;
          console.log('width-height: ', width + '-' + height)
        }
      }
    },
    playVideo() {
      this.videoLoading = true;
      console.log(this.detailData.deviceCode, 'play device video')
      // 播放
      this.$api.postDevicePlayVideo({
        entity: {
          deviceCode: this.detailData.deviceCode,
        }
      }).then(res => {
        if (res) {
          this.videoUrl = res.play_url;
          // this.videoHeight = res.mediaInfo.height;
          // this.videoWidth = res.mediaInfo.width;
          this.videoHeight = 9;
          this.videoWidth = 16;
          this.updatePlayerDomSize();
          this.isShowVideo = true;
        } else {
          this.videoUrl = undefined;
          this.isShowVideo = false;
          this.videoHeight = undefined;
          this.videoWidth = undefined;
        }
        this.videoLoading = false;
      })
    },
    getStatesLabel(key, value) {
      if (!value && value != 0) {
        if (key == 'top' || key == 'isTop') {
          value = "0"
        } else {
          return '-'
        }
      }
      let warningStatusColorMap = {
        "0": "#33bf09",
        "1": "#1296db",
        "2": "#ffbb36",
        "3": "#ff8000",
        "4": "#d81e06",
        "-1": "#787b80",
      };
      let states = {
        warningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
        monitorWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
        deviceWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
      }
      let labels = []
      labels = states[key].filter(item => {
        if (typeof (value) == 'number' || typeof (value) == 'boolean') {
          return value + "" == item.value + ''
        } else {
          return value == item.value + ''
        }
      })
      let labelStr = [], color = ''
      for (let item of labels) {
        labelStr.push(item.label)
        if (item.color) {
          color = item.color
        }
      }
      labelStr = labelStr.join(',')
      return { label: labelStr.length ? labelStr : '', color: color }
    },
    // 封装数据
    onCopy(data, msg) {
      let _msg = msg ? msg : '复制成功!';
      console.log(data)
      if (window.clipboardData) {
        window.clipboardData.setData('text', data);
        this.$message.success(_msg)
      } else {
        (function () {
          document.oncopy = function (e) {
            e.clipboardData.setData('text', data);
            e.preventDefault();
            document.oncopy = null;
          }
        })('要复制的内容');
        document.execCommand('Copy');
        this.$message.success(_msg)
      }
    },
    close() {
      this.$emit('close', this.detailData)
    },

  },
}

</script>

<style scoped lang="scss">
.video {
  display: flex;
  justify-content: center;  /* 水平居中 */
  align-items: center;      /* 垂直居中 */
  width: 100%;
  height: calc(100% - 50px);
}

.dl-title {
  line-height: 16px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 14px;
  // color:#91cc75;
  font-weight: 600;
}

.icon {
  margin-right: 5px;
  display: block;
  font-size: 22px;
  cursor: pointer;
  color: #c4c7cd;
  transition: color 0.3s;
}

.icon:hover {
  color: #409EFF;
  /* 悬停时的颜色 */
}


.p-30 {
  padding: 15px;
  box-sizing: border-box;
}

.status1-0 {
  color: #33bf09
}

.status1-1 {
  //font-weight: bold;
  color: #1296db
}

.status1-2 {
  //font-weight: bold;
  color: #ffbb36
}

.status1-3 {
  //font-weight: bold;
  color: #ff8000
}

.status1-4 {
  //font-weight: bold;
  color: #d81e06
}

.status1-5 {
  //font-weight: bold;
  color: #bfbfbf
}
</style>