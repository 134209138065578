<template>
  <div class="h-100 w-100 p-30" style="position: relative;">
    <div style="cursor: move;">
      <div v-drag="{ _this: index, isFullDl: detailData.isFullDl, v: this }" style="cursor: move;" class="flex-row">
        <span class="dl-title">
          <template v-if="!deviceDataPage">
            <el-tag size="mini" style="margin-right: 5px; margin-top: 5px" effect="plain"
              @click="onCopy(detailData.monitorName, '监测点名称 复制成功!')">{{ detailData.monitorName }}</el-tag>
            <el-tag type="warning" style="margin-right: 5px; margin-top: 5px" effect="plain" size="mini"
              @click="onCopy(detailData.deviceCode, '设备编号 复制成功!')">{{ detailData.deviceCode }}</el-tag>
            <el-tag type="warning" style="margin-right: 5px; margin-top: 5px" effect="plain" size="mini"
              @click="onCopy(detailData.deviceName, '设备名称 复制成功!')">{{ detailData.deviceName }}</el-tag>
            <el-tag :color="getStatesLabel('warningStatus', detailData.warningStatus).color"
              style="margin-right: 5px; margin-top: 5px; color: white" size="mini" v-if="detailData.warningStatus">
              {{ getStatesLabel('warningStatus', detailData.warningStatus).label }}</el-tag>
          </template>
        </span>
        <div class="flex-row" :class="{ 'flex-r': deviceDataPage }">
          <div class="icon el-icon-refresh" title="刷新" @click="refreshMethod"></div>
          <div class="icon el-icon-download" title="下载" @click="downloadMethod"></div>
          <div class="icon el-icon-full-screen" v-if="!deviceDataPage" title="全屏"
            @click="handleFull(chartData.labelTag)"></div>
          <div class="icon el-icon-circle-close" v-if="!deviceDataPage" title="关闭" @click="close"></div>
        </div>
      </div>
      <div v-drag="{ _this: index, isFullDl: detailData.isFullDl, v: this }" class="flex-row"
        :class="{ 'flex-sp': deviceDataPage }" style="margin-top: 8px">
        <el-radio-group class="tag-title" v-model="chartData.labelTag" size="mini">
          <el-radio-button label="1">数据曲线</el-radio-button>
          <el-radio-button label="2"
            :disabled="detailData.deviceTypeCode == 'normal_aggregate' || detailData.deviceTypeCode == 'deep_displacement_aggregate'">数据列表</el-radio-button>
        </el-radio-group>
        <div class="flex-row">
          <h3 style="margin-right: 10px;" v-if="deviceDataPage">快捷选择</h3>
          <el-radio-group v-model="tagTime" size="small" style="margin-right:5px" @input="setTagTime($event, '1')">
            <el-radio-button label="1">1天</el-radio-button>
            <el-radio-button label="7">1周</el-radio-button>
            <el-radio-button label="30">1月</el-radio-button>
          </el-radio-group>
          <el-date-picker v-model="datetimerange" type="datetimerange" range-separator="-" size="mini"
            @change="setTagTime($event)" style="margin-right:30px;width:200px" start-placeholder="开始日期"
            end-placeholder="结束日期" align="right" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
            :clearable="false">
          </el-date-picker>
          <el-radio-group v-model="showMode" size="small" style="margin-right:5px">
            <el-radio-button label="x">X方向</el-radio-button>
            <el-radio-button label="y">Y方向</el-radio-button>
          </el-radio-group>
        </div>
      </div>
    </div>
    <div v-loading="loading" class="h-100 w-100">
      <div v-if="chartData.labelTag === '1'" class="h-100 w-100"
        style="padding:15px 0 0 0 ; display: flex; flex-direction: column">
        <!--        <el-radio-group v-model="showMode" size="mini" style="margin-top: 5px">-->
        <!--          <el-radio label="x">X方向</el-radio>-->
        <!--          <el-radio label="y">Y方向</el-radio>-->
        <!--        </el-radio-group>-->
        <div v-if="chartData.seriesData && chartData.seriesData.length" class="h-100 w-100">
          <div v-if="showEchartDl" :id="'container' + index"
            :style="{ width: '100%', height: detailData.isFullDl ? 'calc(100% - 100px)' : contentHeight + 'px' }">
          </div>
        </div>
        <div v-else class="h-100 w-100">
          <el-empty
            :style="{ width: '100%', padding: '15px 0', height: detailData.isFullDl ? 'calc(100% - 50px)' : contentHeight + 'px' }"
            description="请选择时间点"></el-empty>
        </div>
      </div>
      <div v-else-if="tableData.length && chartData.labelTag == '2'" class="h-100 w-100">
        <jTable v-model="columnConfig" ref="jTable" class="j-table-dialog" :height="detailData.isFullDl ? '' : '400px'"
          v-if="showEchartDl" :class="{ 'j-table-dialog-full': detailData.isFullDl }" :tableData="tableData"
          :total="total" @search="search" :controlName="''">
        </jTable>
      </div>
      <el-empty class="h-100 w-100" :style="{ padding: '15px 0', height: contentHeight + 'px' }" v-else></el-empty>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'deepDisplacementLine',
  props: {
    detailData: {
      type: Object,
      default: () => { }
    },
    deviceDataPage: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      chartData: {
        labelTag: '1',
        seriesData: [],
      },
      datetimerange: [this.$tool.getFormatTime(1), this.$tool.getFormatTime(0)],
      total: 0,
      loading: true,
      tableData: [],
      columnConfig: [],
      // showTable: false,
      keyList: [],
      tagTime: 1,
      myChart: {},
      showEchartDl: true,
      // 时间列表
      timeList: [],
      // 内容高度
      contentHeight: 700,
      // 展示模式
      showMode: 'x',
    }
  },
  // 自定义指令 —— 拖动div
  directives: {
    drag(el, info) {
      info.value.v.$nextTick(() => {
        let oDiv = el // 当前元素
        document.onselectstart = function () {
          return false
        }
        let prentDiv = document.getElementsByClassName('echartWarp' + info.value._this)[0]
        oDiv.onmousedown = function (e) {
          // 鼠标按下，计算当前元素距离可视区的距离
          let disX = e.clientX - prentDiv.offsetLeft
          let disY = e.clientY - prentDiv.offsetTop
          document.onmousemove = function (e) {
            // 通过事件委托，计算移动的距离
            let l = e.clientX - disX
            let t = e.clientY - disY
            // 移动当前元素
            if (!info.value.isFullDl) {
              prentDiv.style.left = l + 'px'
              prentDiv.style.top = t + 'px'
            }
          }
          document.onmouseup = function (e) {
            document.onmousemove = null
            document.onmouseup = null
            document.onselectstart = function () {
              return true
            }
          }
          return false
        }
      })
    }
  },
  mounted() {
    // this.init()
  },
  watch: {
    'chartData.labelTag': {
      handler(val) {
        this.init()
      },
      immediate: true,
    },
    "detailData.isFullDl": {
      handler(val) {
        this.showEchartDl = false
        this.$nextTick(() => {
          this.showEchartDl = true
        })
      },
    },
    "showMode": {
      handler() {
        this.onExtraChartsData();
      }
    }
  },
  destroyed() {
    this.tagTime = ""
  },
  methods: {
    // 封装数据
    onExtraChartsData() {
      this.chartData.seriesData = []
      this.timeList.map(times => {
        if (this.showMode === 'x') {
          if (times && times.xData && times.xData.length) {
            console.log(times.time + ' X方向')
            this.chartData.seriesData.push({
              name: times.time + ' X方向',
              type: 'line',
              symbolSize: 5,
              data: times.xData,
              smooth: true,
            })
          }
        } else if (this.showMode === 'y') {
          if (times && times.yData && times.yData.length) {
            console.log(times.time + ' Y方向')
            this.chartData.seriesData.push({
              name: times.time + ' Y方向',
              type: 'line',
              symbolSize: 5,
              data: times.yData,
              smooth: true,
            })
          }
        }
      })
      this.setOptions()
    },
    setToken() {
      let access_token = sessionStorage.getItem('access_token').replace('bearer ', '')
      return '?access_token=' + access_token
    },
    getStatesLabel(key, value) {
      if (!value && value != 0) {
        if (key == 'top' || key == 'isTop') {
          value = "0"
        } else {
          return '-'
        }
      }
      let warningStatusColorMap = {
        "0": "#33bf09",
        "1": "#1296db",
        "2": "#ffbb36",
        "3": "#ff8000",
        "4": "#d81e06",
        "-1": "#787b80",
      };
      let states = {
        warningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
        monitorWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
        deviceWarningStatus: JSON.parse(sessionStorage.getItem('dvCode')).DEVICE.WARNING_STATUS.map(item => {
          return { label: item.codeDesc, value: item.codeValue, color: warningStatusColorMap[item.codeValue] }
        }),
      }
      let labels = []
      labels = states[key].filter(item => {
        if (typeof (value) == 'number' || typeof (value) == 'boolean') {
          return value + "" == item.value + ''
        } else {
          return value == item.value + ''
        }
      })
      let labelStr = [], color = ''
      for (let item of labels) {
        labelStr.push(item.label)
        if (item.color) {
          color = item.color
        }
      }
      labelStr = labelStr.join(',')
      return { label: labelStr.length ? labelStr : '', color: color }
    },
    onCopy(data, msg) {
      let _msg = msg ? msg : '复制成功!';
      console.log(data)
      if (window.clipboardData) {
        window.clipboardData.setData('text', data);
        this.$message.success(_msg)
      } else {
        (function () {
          document.oncopy = function (e) {
            e.clipboardData.setData('text', data);
            e.preventDefault();
            document.oncopy = null;
          }
        })('要复制的内容');
        document.execCommand('Copy');
        this.$message.success(_msg)
      }
    },
    close() {
      this.$emit('close', this.detailData)
    },
    handleFull(labelTag) {
      // this.init()
      this.$emit('handleFull', labelTag)
      this.showEchartDl = false;
      this.$nextTick(() => {
        this.showEchartDl = true;
        this.$nextTick(() => {
          this.refreshEcharts();  // Ensure this is the very last thing that happens after all DOM updates
        });
      });
    },
    setTagTime(e, type) {
      if (type) {
        this.datetimerange = [this.$tool.getFormatTime(this.tagTime * 1), this.$tool.getFormatTime(0)]
      } else {
        this.tagTime = ""
      }
      if (this.chartData.labelTag == '1') {
        this.queryChart()
      } else {
        this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
        this.queryList({ pageNum: 1, pageSize: 50 })
      }
    },
    init() {
      // this.datetimerange = [this.$tool.getFormatTime(15), this.$tool.getFormatTime(0)]
      if (this.chartData.labelTag == '1') {
        this.queryChart()
      } else {
        this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
        this.queryList({ pageNum: 1, pageSize: 50 })
      }
    },
    refreshEcharts() {
      // const _chartData = this.chartData.seriesData;
      // this.chartData.seriesData = []
      this.setOptions();
    },
    queryChart() {
      this.queryDevicePropertiesData()
    },
    queryList(formData) {
    },
    queryDevicePropertiesData() {
      this.loading = true
      let startTime = null
      let endTime = null
      if (Array.isArray(this.datetimerange) && this.datetimerange.length === 2) {
        startTime = this.datetimerange[0]
        endTime = this.datetimerange[1]
      }
      let entity = {
        deviceCode: this.detailData.deviceCode,
        startTime: startTime,
        endTime: endTime,
      }
      this.$api.queryDeepDisplacementDeviceChartData({ entity }).then(resData => {
        if (resData) {
          console.log('resData', resData)
          // 所有数据
          this.timeList = resData.map(item => {
            let xData = []
            let yData = []
            // x方向位移
            if (item.xdata && item.xdata.length) {
              xData = item.xdata.map(cItem => {
                return [cItem.distance, cItem.depth / 1000, cItem.unit || '米'];
              })
            }
            // y方向位移
            if (item.ydata && item.ydata.length) {
              yData = item.ydata.map(cItem => {
                return [cItem.distance, cItem.depth / 1000, cItem.unit || '米'];
              })
            }
            return {
              time: item.ts,
              xData: xData,
              yData: yData,
            }
          });
          console.log(this.timeList)
          // 根据选择的显示模式封装chartsData
          this.onExtraChartsData()
        }
        this.loading = false;
      })
    },
    setOptions() {
      let _this = this;

      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross', // 使用交叉型指示器
            crossStyle: {
              color: '#999', // 设置交叉线的颜色
              type: 'dashed', // 设置交叉线的类型为虚线
              width: 1, // 设置交叉线的宽度
            },
          },
          enterable: true, // 鼠标是否可进入提示框浮层中
          // hideDelay: 200, // 浮层隐藏的延迟
          backgroundColor: 'rgba(255,255,255, 1)',
          extraCssText: 'box-shadow: 0 0 3px rgba(150,150,150, 0.7);',
          formatter: function (params) {
            console.log(params)
            let depthString = `<div style="height: auto;max-height: 360px;overflow-y: auto;">安装深度: ${params[0].value[1]} ${params[0].value[2]}<br/>`;
            let valueStrings = params.map(param => {
              let color = param.color; // 获取该数据项对应的颜色
              return `<span style="display:inline-block;width:10px;height:10px;background-color:${color};margin-right:5px;"></span>${param.seriesName}: ${param.value[0]} mm`;
            }).join('<br/>');
            valueStrings += '</div>'
            return depthString + valueStrings;
          }
        },
        legend: {
          type: 'scroll',
          orient: 'vertical',
          pageButtonPosition: 'end',
          x: 'left',      //可设定图例在左、右、居中
          y: 'top',     //可设定图例在上、下、居中
          data: _this.chartData.seriesData.map(series => series.name),
          left: '20',
          top: '2%',
          itemGap: 20,
          selector: [
            {
              // 全选
              type: 'all',
              // 可以是任意你喜欢的标题
              title: '全选'
            },
            {
              // 反选
              type: 'inverse',
              // 可以是任意你喜欢的标题
              title: '反选'
            }
          ]
        },
        grid: {
          top: '30',
          left: '240',
          right: '80',
          // bottom: '12%',
          containLabel: true
        },
        dataZoom: [
          {
            type: 'slider',  // 显示下方的滑动条
            start: 0,        // 显示范围从 0% 开始
            end: 100,        // 显示范围到 100% 结束
            xAxisIndex: 0,
            preventDefaultMouseMove: true
          },
          {
            type: 'inside',
            xAxisIndex: 0,
            zoomOnMouseWheel: true, // 滚轮是否触发缩放
            preventDefaultMouseMove: true
          }
        ],
        // xAxis: {
        //   type: 'time',
        //   boundaryGap: ['5%', '5%'], // Add this line to leave space on both sides
        // },
        // yAxis: {
        //   // minInterval: 0.5,
        // },
        // series: _this.chartData.seriesData,
        xAxis: {
          type: 'value',
          name: '位移(mm)',
        },
        yAxis: {
          type: 'category',
          name: '深度(m)',
        },
        series: _this.chartData.seriesData,
      }
      this.$nextTick(() => {
        let dom = document.getElementById('container' + this.index);
        this.myChart = echarts.init(dom);
        if (option) {
          this.myChart.setOption(option, true);
        }

        window.addEventListener('resize', this.myChart.resize);
      })
    },
    convertToBeijingTime(timestamp) {
      return new Date(timestamp).toLocaleString()
    },
    search(formData) {
      this.queryList(formData)
    },
    downloadMethod() {
      if (this.chartData.labelTag == '1') {
        this.exportEchart()
      } else {
        this.exportTableData()
      }
    },
    refreshMethod() {
      if (this.chartData.labelTag == '1') {
        this.queryChart()
      } else {
        this.$refs.jTable && (this.$refs.jTable.page = { pageNum: 1, pageSize: 50 })
        this.queryList({ pageNum: 1, pageSize: 50 })
      }
    },
    // 导出单个图表图片
    exportEchart() {
      var img = new Image();
      img.src = this.myChart.getDataURL({
        type: "png",
        pixelRatio: 1, //放大2倍
        backgroundColor: "#fff",
      });
      let _this = this
      img.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        var a = document.createElement("a");
        var event = new MouseEvent("click");
        a.download = _this.detailData.deviceName + '-' + (_this.detailData.propertyType == 'STATE' ? '设备状态' : '监测数据');
        // 将生成的URL设置为a.href属性
        a.href = dataURL;
        // 触发a的单击事件
        a.dispatchEvent(event);
        a.remove();
      };
    },
    exportTableData() {
      let entity = {
        deviceCode: this.detailData.deviceCode,
        // propertys: this.detailData.propertys,
        propertyType: this.detailData.propertyType,
      }
      if (Array.isArray(this.datetimerange) && this.datetimerange.length == 2) {
        let startTime = this.datetimerange[0]
        let endTime = this.datetimerange[1]
        if (startTime && endTime) {
          console.log(startTime + '  ' + endTime)
          entity.where = `createTime<=${new Date(endTime).getTime()} and createTime>=${new Date(startTime).getTime()}`
        }
      }
      this.$api.downloadEchartData({ entity }).then(res => {
        if (res) {
          let fileName = this.detailData.deviceName + '-' + (this.detailData.propertyType == 'STATE' ? '设备状态' : '监测数据');
          this.$tool.downFileByUrl(res.fileId, fileName)
        }

      })
    },
  },
}

</script>

<style scoped lang="scss">
.j-table-dialog {
  max-height: 850px;
  overflow-y: scroll;
}

.j-table-dialog-full {
  max-height: calc(100% - 100px) !important;
  min-height: calc(100% - 100px) !important;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
  padding: 7px 15px;
}

::v-deep .el-date-editor--datetimerange.el-input__inner {
  width: 320px;
}

.dl-title {
  line-height: 16px;
  margin-bottom: 10px;
  display: inline-block;
  font-size: 14px;
  // color:#91cc75;
  font-weight: 600;
}

.icon {
  margin-right: 5px;
  display: block;
  font-size: 22px;
  cursor: pointer;
  color: #c4c7cd;
  transition: color 0.3s;
}

.icon:hover {
  color: #409EFF;
  /* 悬停时的颜色 */
}

.help {
  width: 24px;
  height: 32px;
  opacity: 0.5;
}

.p-30 {
  padding: 15px;
  box-sizing: border-box;
}

.status1-0 {
  color: #33bf09
}

.status1-1 {
  //font-weight: bold;
  color: #1296db
}

.status1-2 {
  //font-weight: bold;
  color: #ffbb36
}

.status1-3 {
  //font-weight: bold;
  color: #ff8000
}

.status1-4 {
  //font-weight: bold;
  color: #d81e06
}

.status1-5 {
  //font-weight: bold;
  color: #bfbfbf
}
</style>