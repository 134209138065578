<template>
    <div>
        <div v-clickoutside="closeTree">
            <div @click="inputFocus">
              <el-input clearable v-model="inputValue" :disabled="disabled" :style="'width:' + width"></el-input>
            </div>
            <div class="treeModule">
                <el-tree class="ORGTree" v-show="ishowTree" ref="tree" :data="dataList" :disabled="disabled"
                    :key="defaultProps.value" :node-key="defaultProps.value" highlight-current :filter-node-method="filterNode"
                    @node-click="handleNodeClick" :props="defaultProps">
                </el-tree>
            </div>
        </div>
    </div>
</template>

<script>
import Clickoutside from "element-ui/src/utils/clickoutside";
export default {
    directives: { Clickoutside },
    props: {
        value: {
            type: String,
            default: ''
        },
        dataList: {
            type: Array,
            default: () => {
                return []
            }
        },
        disabled: {
            type: Boolean,
            default: false
        },
        defaultProps: {
            type: Object,
            default: () => {
                return { children: 'childOrgVos', label: 'orgName', value: 'orgNum' }
            }
        },
        width: {
            type: String,
            default: '100%'
        }
    },
    data() {
        return {
            mineStatusValue: [],
            inputValue: '',
            dataLists: [],
            ishowTree: false,
            defaultCheckedKeys: []
        }
    },
    mounted() {
        console.log('mounted', this.value)
    },
    watch: {
        value: {
            handler(val) {
                if (!val) {
                    this.inputValue = ''
                } else {
                    this.defaultCheckedKeys = [val]
                    this.inputValue = this.getTreeLabel(this.dataList, val)
                }
            },
            immediate: true,
        },
        inputValue(val) {
            this.$refs.tree.filter(val);
        },
    },
    methods: {
        filterNode(value, data) {
            // if (!value) return true;
            // return data[this.defaultProps.label].indexOf(value) !== -1;
          return true;
        },
        inputFocus() {
            if (this.ishowTree == true) {
                this.ishowTree = false
            } else {
                this.ishowTree = true
            }
        },
        getTreeLabel(list, id) {
            for (let li of list) {
                if (li[this.defaultProps.value] == id) {
                    return li[this.defaultProps.label]
                }
                if (li[this.defaultProps.children] && li[this.defaultProps.children].length) {
                    let res = this.getTreeLabel(li[this.defaultProps.children], id)
                    if (res && typeof res == "string") {
                        return res
                    }
                }
            }
        },
        closeTree() {
            this.ishowTree = false
        },
        handleNodeClick(e) {
            this.inputValue = e[this.defaultProps.label]
            this.closeTree()
            this.$emit('input', e[this.defaultProps.value])
        }
    }

}
</script>

<style scoped>
.treeModule {
    position: absolute;
    /*这里一定要设置*/
    z-index: 999999;
    /*这里是该元素与显示屏的距离，据说越大越好，但是我也没有看到效果，因为没有它也是可以的*/
}

.ORGTree {
    min-width: 220px;
    height: 300px;
    overflow: auto;
    border: 1px solid #ccc7c7;

}

.el-input {
    max-width: 250px;
}
</style>