<template>
  <div class="layout-main">
    <Header class="Header" />
    <el-container>
      <Aside v-if="!['map', 'cockpit'].includes($route.name)" class="Aside" />
      <el-main :style="!['map', 'home', 'cockpit'].includes($route.name) ? 'background: #fff;' : ''">
        <eMain />
      </el-main>
    </el-container>
    <el-footer>
      <div class="footer">
        <a>建议您使用IE9+、FireFox、Google Chrome、分辨率1366*768及以上浏览网站，获取更好的用户体验</a>
        <br />
        <a href="https://beian.miit.gov.cn">闽ICP备19019241号</a>
      </div>
    </el-footer>
  </div>
</template>
<script>
import Aside from './children/Aside.vue'
import Header from './children/Header.vue'
import eMain from './children/eMain.vue'


export default {
  name: 'Layout',
  components: {
    Aside, Header, eMain
  },
  data() {
    return {
      showHeader: false
    }
  },
  mounted() {
    if (this.$store.state.systemConfig.iconFileUrl) {
      this.resetLink(this.$store.state.systemConfigmConfig.iconFileUrl)
    } else {
      this.resetLink("/file/get/a938d278e3dd4bd520240608003027"+this.setToken())
    }
    if (this.$store.state.systemConfig.systemName) {
      this.resetTitle(this.$store.state.systemConfig.systemName)
    } else {
      // this.resetTitle("地质灾害预警管理系统")
      this.resetTitle("监测预警管理系统")
    }
  },
  methods: {
    setToken() {
      let access_token = sessionStorage.getItem('access_token').replace('bearer ', '')
      return '?access_token=' + access_token
    },
    resetTitle(systemName) {
      const title = document.querySelector('title[rel~="title"]');
      if (title) {
        title.innerHTML = systemName;
      } else {
        const newTitle = document.createElement('title');
        newTitle.rel = 'title';
        newTitle.href = systemName;
        document.getElementsByTagName('head')[0].appendChild(newTitle);
      }
    },
    resetLink(iconFileUrl) {
      // 动态替换favicon
      const link = document.querySelector('link[rel~="icon"]');
      if (link) {
        link.href = this.$FileBaseURL + iconFileUrl;
      } else {
        const newLink = document.createElement('link');
        newLink.rel = 'icon';
        newLink.href = this.$FileBaseURL + iconFileUrl;
        document.getElementsByTagName('head')[0].appendChild(newLink);
      }
    },
  }
}
</script>
<style scoped lang="scss">
.Header {
  color: $title;
  width: 100%;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 0 25px 0 0;
}

.layout-main {
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 100%;
  background-image: url('../assets/uiBack/bg1.png');
  background-size: cover;
}

.Aside,
.Header {
  color: #fff;
  // background-color: $aside;
}

.el-main {
  /* width:calc(100% - 40px); */
  color: $title;
  min-height: calc(100vh - 50px);
  // min-width: 1000px;
  padding: 0;
  border-radius: 10px 10px 0 0;

}

body>.el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}

.footer {
  font-family: Alibaba PuHuiTi;
  line-height: normal;
  font-size: 14px;
  width: 100%;
  text-align: center;
  //background: #fff;
  padding: 10px 0;
}
</style>
