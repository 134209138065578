import jSearch from "./jSearch/index.js"
import jTable from "./jTable/index.js"
import jTitle from "./jTitle/index.js"
import jTree from "./jTree/index.js"
import jdTree from "./jdTree/index.js"
import jForm from "./jForm/index.js"
import jUploadImg from "./jUploadImg/index.js"
import jUploadImg2 from "./jUploadImg2/index.js"
import jUploadFile from "./jUploadFile/index.js"
import importExecl from "./importExecl/index.js"
import jTableDialog from "./jTableDialog/index.js"
import jSelectTable from "./jSelectTable/index.js"
import jRadioTable from "./jRadioTable/index.js"
import jSelectTree from "./jSelectTree/index.js"
import jMenuTree from "./jMenuTree/index.js"
import jTableBox from "./jTableBox/index.js"
import jBindDevice from "./jBindDevice/index.js"
import jCheckBoxTable from "./jCheckBoxTable/index.js"
import jCheckGroup from "./jCheckGroup/index.js"
import jWarningSelect from "./jWarningSelect/index.js"

export default {
    install(Vue) {
        Vue.component('jSearch', jSearch);//注册组件
        Vue.component('jTable', jTable);//注册组件
        Vue.component('jTitle', jTitle);//注册组件
        Vue.component('jTree', jTree);//注册组件
        Vue.component('jdTree', jdTree);//注册组件
        Vue.component('jForm', jForm);//注册组件
        Vue.component('jUploadImg', jUploadImg);//注册组件
        Vue.component('jUploadFile', jUploadFile);//注册组件
        Vue.component('importExecl', importExecl);//注册组件
        Vue.component('jSelectTable', jSelectTable);//注册组件
        Vue.component('jTableDialog', jTableDialog);//注册组件
        Vue.component('jRadioTable', jRadioTable);//注册组件
        Vue.component('jSelectTree', jSelectTree);//注册组件
        Vue.component('jMenuTree', jMenuTree);//注册组件
        Vue.component('jTableBox', jTableBox);//注册组件
        Vue.component('jBindDevice', jBindDevice);//注册组件
        Vue.component('jUploadImg2', jUploadImg2);//注册组件
        Vue.component('jCheckBoxTable', jCheckBoxTable);//注册组件
        Vue.component('jCheckGroup', jCheckGroup);//注册组件
        Vue.component('jWarningSelect', jWarningSelect);//注册组件
        
    }
}