import Moment from 'moment';
import Api from '@/request/childApi/com'
import { Message } from 'element-ui'
Moment.locale('zh-cn');

function setForEach() {
    if (!Array.prototype.forEach) {
        Array.prototype.forEach = function forEach(callback, thisArg) {
            var T, k;
            if (this == null) {
                throw new TypeError("this is null or not defined");
            }
            var O = Object(this);
            var len = O.length >>> 0;
            if (typeof callback !== "function") {
                throw new TypeError(callback + " is not a function");
            }
            if (arguments.length > 1) {
                T = thisArg;
            }
            k = 0;
            while (k < len) {

                var kValue;
                if (k in O) {
                    kValue = O[k];
                    callback.call(T, kValue, k, O);
                }
                k++;
            }
        };
    }
}
function setIndexOf() {
    if (!Array.indexOf) {
        Array.prototype.indexOf = function (obj) {
            for (var i = 0; i < this.length; i++) {
                if (this[i] == obj) {
                    return i;
                }
            }
            return -1;
        }
    }
}
function hasEmptyObj(obj, isNonEmpty) {
    if (typeof obj === "object") {
        for (let i in obj) {
            var arr = obj[i]
            if (typeof obj[i] === "object") {
                if (Object.prototype.toString.call(obj[i]) === "[object Object]") {
                    arr = Object.values(obj[i])
                }
                if (arr.length > 0) {
                    hasEmptyObj(obj[i], isNonEmpty)
                } else {
                    isNonEmpty.push(false) //{}
                }
            } else {
                if (!obj[i]) {
                    isNonEmpty.push(false) // ""
                }
            }
        }
    }
    return isNonEmpty.length === 0
}
var isIE = function () {
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
        return true
    } else {
        return false
    }
}
var getTableKey=function(codeDesc){
    let USER_PAGE_CONFIG = JSON.parse(sessionStorage.getItem('dvCode')).RBAC.USER_PAGE_CONFIG
    let tableInfo = USER_PAGE_CONFIG.find(item=>item.codeDesc==codeDesc)
    return tableInfo.codeValue
}
var downExcel = function (resultData, setFileName, getFileName) { //导出execl
    // 使用说明： 请求字段里面加上 responseType:"blob"
    // 参数说明:  downExcel(result.data,'模板名称',result.headers['content-disposition']?result.headers['content-disposition']:false)
    let blob = new Blob([resultData], { type: "application/vnd.ms-excel;charset=utf-8" })
    var fileName
    if (getFileName) {
        decodeURI(getFileName)
        var arr = getFileName.split('.'), fileType = arr[arr.length - 1]
        fileName = setFileName + '.' + fileType
    } else {
        fileName = setFileName + '.xlsx'
    }
    if (isIE()) {
        window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else {
        let elink = document.createElement('a')
        elink.download = fileName
        elink.style.display = "none"
        elink.href = URL.createObjectURL(blob)
        document.body.appendChild(elink)
        elink.click()
        document.body.removeChild(elink)
    }
}
var goDownloadExcel = function (ref, fileName) {
    if (ref.data) {
        let lists = ref.data;
        var fileName = decodeURIComponent(fileName);
        console.log(lists);
        var blob = new Blob([lists], { type: 'application/vnd.ms-excel' });
        if ('download' in document.createElement('a')) {//非IE下载
            var downloadElement = document.createElement('a');
            var href = window.URL.createObjectURL(blob); //创建下载的链接
            downloadElement.href = href;
            downloadElement.download = fileName; //下载后文件名
            document.body.appendChild(downloadElement);
            downloadElement.click(); //点击下载
            document.body.removeChild(downloadElement); //下载完成移除元素
            window.URL.revokeObjectURL(href); //释放掉blob对象
        } else { //IE 10+ 下载
            navigator.msSaveBlob(blob, fileName);
        }
    }
}
export default {
    myBrowser() { //浏览器类型
        var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
        var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器
        var isIE = userAgent.indexOf("compatible") > -1
            && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
        var isEdge = userAgent.indexOf("Edge") > -1; //判断是否IE的Edge浏览器
        var isFF = userAgent.indexOf("Firefox") > -1; //判断是否Firefox浏览器
        var isSafari = userAgent.indexOf("Safari") > -1
            && userAgent.indexOf("Chrome") == -1; //判断是否Safari浏览器
        var isChrome = userAgent.indexOf("Chrome") > -1
            && userAgent.indexOf("Safari") > -1; //判断Chrome浏览器
        if (isIE) {
            var reIE = new RegExp("MSIE (\\d+\\.\\d+);");
            reIE.test(userAgent);
            var fIEVersion = parseFloat(RegExp["$1"]);
            if (fIEVersion == 7) {
                return "IE7";
            } else if (fIEVersion == 8) {
                return "IE8";
            } else if (fIEVersion == 9) {
                return "IE9";
            } else if (fIEVersion == 10) {
                return "IE10";
            } else if (fIEVersion == 11) {
                return "IE11";
            } else {
                return "0";
            }//IE版本过低
            return "IE";
        }
        if (isOpera) {
            return "Opera";
        }
        if (isEdge) {
            return "Edge";
        }
        if (isFF) {
            return "FF";
        }
        if (isSafari) {
            return "Safari";
        }
        if (isChrome) {
            return "Chrome";
        }

    },
    getFormatTime(dayNum) {
        // 当日日期
        let nowDate = new Date();
        let nowfiveTime = new Date().setDate(nowDate.getDate() - dayNum);
        return Moment(nowfiveTime).format('YYYY-MM-DD HH:mm:ss')
    },
    downFileByUrl(fileId, fileName,msg) {
        let elink = document.createElement('a')
        let access_token = sessionStorage.getItem('access_token').replace('bearer', '').replace(/\s*/g, '')
        elink.href = Api.getFileByUrl() + '/' + fileId + `?access_token=${access_token}`
        elink.setAttribute('download', fileName||'')
        elink.style.display = "none"
        msg&&Message.success(msg)
        document.body.appendChild(elink)
        elink.click()
        document.body.removeChild(elink)
    },
    setFormatTime(propTime) {
        // 当日日期
        let time = new Date(propTime);
        return Moment(propTime).format('YYYY-MM-DD hh:ss:mm')
    },
    unique9(arr) { //数组去重
        var res = [];
        for (var i = 0; i < arr.length; i++) {
            res.lastIndexOf(arr[i]) !== -1 ? '' : res.push(arr[i]);
        }
        return res;
    },
    uniqueArrObject(arr,key) { //数组对象去重
        var res = [];
        for(let item of arr){
            let keys = res.filter((item=>!!item[key])).map(item=>item[key])
            if(!keys.includes(item[key])){
                res.push(item)
            }
        }
        return res;
    },
    compatibleIE() {
        setForEach()
        setIndexOf()
        console.log("兼容ie")
    },
    tableHeaderColor(row, column, rowIndex, columIndex) {
        return "background:rgba(0,0,0,0.04)"
    },
    hasEmptyObj,
    downExcel,
    goDownloadExcel,
    getTableKey
}