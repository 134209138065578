<template>
  <div>
    <div v-for="(item, i) in dlList"
         v-if="item.show"
         :key="i"
         @click="setzIndex(i)">
      <div v-if="item.deviceTypeCode == 'deep_displacement_aggregate'" :class="'echartWarp' + i + (!item.isFullDl?' deepDisplacementEchartsWarp':' echartsWarpFull')">
        <deep-displacement-line
            :class="' echartWarp' + i + (item.isFullDl?' e-w-100':'')"
            :index="i"
            :detailData="item"
            class="echartline"
            @close="closeDialog($event, item)"
            @handleFull="handleFull(item)"/>
      </div>
      <div v-else-if="item.deviceTypeCode == 'video'" :class="'echartWarp' + i + (!item.isFullDl?' videoWarp':' echartsWarpFull')">
        <device-video-viewer
            :class="' echartWarp' + i + (item.isFullDl?' e-w-100':'')"
            :index="i"
            :detailData="item"
            class="echartline"
            @close="closeDialog($event, item)"/>
      </div>
      <div v-else :class="'echartWarp' + i + (!item.isFullDl?' normalEchartsWarp':' echartsWarpFull')">
        <echartline
            :class="' echartWarp' + i + (item.isFullDl?' e-w-100':'')"
            :index="i"
            :detailData="item"
            class="echartline"
            @close="closeDialog($event, item)"
            @handleFull="handleFull(item)"/>
      </div>
    </div>
  </div>
</template>
<script>
import echartline from "@/components/jDataCharts/lineMap.vue";
import DeepDisplacementLine from "@/components/jDataCharts/deepDisplacementLine.vue";
import DeviceVideoViewer from "@/components/jDataCharts/deviceVideoViewer.vue";

export default {
  name: 'App',
  components: {
    DeviceVideoViewer,
    DeepDisplacementLine,
    echartline
  },
  data() {
    return {
      dlList: [],
      showIds: [],
      maxIndex: 2000,
      deviceName: {},
      isFullDl: true,
      // showEchart:true
    }
  },
  methods: {
    handleFull(item) {
      item.isFullDl = !item.isFullDl
      // this.showEchart=false
      // this.$nextTick(()=>{
      //     this.showEchart=true
      // })
    },
    setzIndex(i) {
      let dom = document.getElementsByClassName('echartWarp' + i)[0]
      dom.style.zIndex = this.maxIndex
      this.maxIndex += 1
    },
    closeDialog(item) {
      this.$set(item, 'show', false)
      this.$set(item, 'isFullDl', false)
      this.showIds = this.showIds.filter(id => id != item.deviceId + '' + item.propertyType)
    },
    async showEchartDialog(data) {
      if (this.showIds.length == 5) {
        this.$message.error('最多添加五个弹窗')
        return
      }
      console.log(data.deviceId + '' + data.propertyType,'dataa')
      if (this.showIds.includes(data.deviceId + '' + data.propertyType)) {
        this.$message.error('已展示图标')
        return
      } else {
        this.showIds.push(data.deviceId + '' + data.propertyType)
      }
      this.dlList.push({
        show: true,
        isFullDl: false,
        productId:data.productId,
        monitorName: data.monitorName,
        propertyType: data.propertyType,
        deviceId: data.deviceId,
        deviceName: data.deviceName,
        deviceCode: data.deviceCode,
        deviceTypeCode: data.deviceTypeCode,
        warningStatus: data.warningStatus,
        id: data.id,
      })
      console.log('this.dlList', this.dlList)
      this.$nextTick(() => {
        this.setzIndex(this.dlList.length - 1);
      })
    },
  },
  mounted() {

  },
}
</script>
<style>
.normalEchartsWarp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 600px;
  height: auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 100
}

.deepDisplacementEchartsWarp {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translate3d(-50%, 0, 0);
  width: 800px;
  height: auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 100
}

.videoWarp {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  min-width: 38vw;
  min-height: 50vh;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 100
}

.e-w-100 {
  width: 100%;
}

.echartsWarpFull {
  position: fixed;
  top: 0 !important;
  left: 0 !important;
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, .15);
  z-index: 100
}
</style>