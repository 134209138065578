<template>
    <el-dialog :title="title" :visible.sync="show" :width="width" @close="cancle">
        <template>
            <jSearch :baseForm="baseForm" @search="search" :span="span" :labelWidth="'100px'" />
            <jTable v-model="columnConfig" ref="jTable" :tableData="tableData" :total="total" @search="search" height="350px"
                @selection="selection" @selectAll="selectAll" :selection="true" controlName="" :checkList="checkList">
            </jTable>
        </template>
        <div slot="footer" class="dialog-footer">
            <el-button size="mini" @click="cancle">取 消</el-button>
            <el-button type="primary" size="mini" @click="submit">确 定</el-button>
        </div>
    </el-dialog>
</template>
<script>

export default {
    name: "jSelectTable",
    props: {
        value: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "70%"
        },
        span: {
            type: Number,
            default: 24
        },
        params: {
            type: Object,
            default: () => { }
        },
        listUrl: {
            type: String,
            default: "monitorInfoQuerypage"
        },
        list: {
            type: Array,
            default: () => []
        },
        title:{
            type:String,
            default:"选择监测点"
        },
        baseForm: {
            type: Object,
            default: () => {
                return {
                    monitorPointNum: { key: 'monitorPointNum', span: 8, label: "监测点编号", labelWidth: "85px", type: 'input', placeholder: "请输入" },
                    monitorPointName: { key: 'monitorPointName', span: 8, label: "监测点", type: 'input', placeholder: "请输入" },
                    monitorProjectName: { key: 'monitorProjectName', span: 8, label: "项目", type: 'input', placeholder: "请输入" },
                    countyCode: { key: 'countyCode', span: 8, label: "行政区划", type: 'area', placeholder: "请选择" },
                }
            }
        },
        columnConfig: {
            type: Array,
            default: () => {
                return [
                    { label: "监测点编号", prop: "monitorPointNum", minWidth: 120 },
                    { label: "监测点", prop: "monitorPointName", minWidth: 100 },
                    { label: "项目", prop: "monitorProjectName", minWidth: 100 },
                    { label: "行政区划", prop: "regionName", minWidth: 100 },
                    { label: "详细地址", prop: "detailAddress", minWidth: 150 },
                    { label: "建设单位", prop: "construction",minWidth:100},
                ]
            }
        },
    },
    data() {
        return {
            tableData: [],
            total: 50,
            show: false,
            checkList: []
        };
    },
    watch: {
        value:{
            handler(val) {
                this.show = val
                if(val){
                    this.queryList({ pageNum: 1, pageSize: 50 })
                }else{
                    this.$refs.jTable&&(this.$refs.jTable.page={pageNum: 1,pageSize:50})
                }
            },
            immediate: true,
        },
        list:{
            handler(val) {
                this.checkList = JSON.parse(JSON.stringify(val))
            },
            immediate: true,
        },
        
    },
    mounted() {
        // this.queryList({ pageNum: 1, pageSize: 50 })
    },
    methods: {
        cancle() {
            this.show = false
            this.checkList = JSON.parse(JSON.stringify(this.list))
            this.$emit('input', false)
        },
        search(formData) {
            console.log(formData,'formData')
            this.queryList(formData)
        },
        async queryList(formData) {
            let params = {
                entity:{...formData,...this.params},
                pageSize: formData.pageSize,
                pageNum: formData.pageNum
            }
            delete params.entity.pageSize
            delete params.entity.pageNum
            let res = await this.$api[this.listUrl](params)
            if (res) {
                this.tableData = res.resultList
                    .map(item => {
                        let regionName = []
                        item.provinceName && regionName.push(item.provinceName)
                        item.cityName && regionName.push(item.cityName)
                        item.countyName && regionName.push(item.countyName)
                        item.regionName = regionName.join('-')
                        return item
                    })
                this.total = res.totalNum
            }
        },
        selection(row) {
            let ids = this.checkList.map(item => item.id)
            if (ids.includes(row.id)) {
                this.checkList = this.checkList.filter(item => item.id != row.id)
            } else {
                this.checkList.push(row)
            }
        },
        selectAll(obj) {
            if (obj.check) {
                let ids = this.checkList.map(item => item.id)
                for (let item of obj.tableData) {
                    !ids.includes(item.id) && this.checkList.push(item)
                }
            } else {
                let ids = obj.tableData.map(item => item.id)
                this.checkList = this.checkList.filter(item => !ids.includes(item.id))
            }
        },
        submit() {
            this.$emit('submit', JSON.parse(JSON.stringify(this.checkList)))
        },
    },
};
</script>
<style scoped lang="scss">
::v-deep .el-alert--info.is-light {
    background: rgba(244, 244, 245, .3);
}

.tips {
    font-family: "Microsoft YaHei", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    color: $error;
    line-height: 22px;
}

.el-input-number {
    position: relative;
    width: 100%;

    ::before {
        content: '人';
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: $border;
    }
}

.fileName {
    color: $primary;
    font-size: 12px;
    cursor: pointer;
}

.down-load {
    ::v-deep .el-form-item__content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-left: 0px !important;
    }
}

::v-deep .el-table__body-wrapper {
    overflow: auto;
    position: relative;
    max-height: 400px;
}

::v-deep .el-dialog {
    margin-top: 5vh !important;
}
</style>