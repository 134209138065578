import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import Api from "@/request/api";
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont-simyng/iconfont.css'
import Component from '@/components'
import '@/assets/public.scss'
import global from '@/util/global'
import tool from '@/util/tool'
import VueRouter from 'vue-router'

let originPush = VueRouter.prototype.push;  //备份原push方法
VueRouter.prototype.push = function (location, resolve, reject) {
  if (resolve && reject) {    //如果传了回调函数，直接使用
    originPush.call(this, location, resolve, reject);
  } else {                     //如果没有传回调函数，手动添加
    originPush.call(this, location, () => { }, () => { });
  }
}
import VueAMap from '@vuemap/vue-amap';
import '@vuemap/vue-amap/dist/style.css'
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'a56acdbda70d8d41a5a83dd69d7618b4',
});
// lazyAMapApiLoaderInstance.then(() => {
//   // your code ...
//   this.map = new AMap.Map('amapContainer', {
//     center: new AMap.LngLat(121.59996, 31.197646)
//   });
// });

Vue.use(VueRouter)
Vue.use(Component);

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$global = global
Vue.prototype.$tool = tool
Vue.prototype.$api = Api
Vue.prototype.$bus = new Vue()
Vue.prototype.URL_LIST = require("../../public/config")
if (process.env.NODE_ENV == 'development') {
  Vue.prototype.$FileBaseURL = '/api';
} else {
  Vue.prototype.$FileBaseURL = '/api';
}
Vue.prototype.$setToken = function(){
  let access_token = sessionStorage.getItem('access_token').replace('bearer ', '')
  return '?access_token=' + access_token
}
//判断一个用户是否有某个页面的角色
Vue.prototype.routeAuthor = function (route) {
  //全量路由
  // console.log(this.$router, 'this.$router')
  let routes = this.$router.getRoutes();
  let keyName = "path"
  //优选用路由名称匹配
  if (route.name) {
    keyName = 'name'
  }
  return routes.some(item => route[keyName].includes(item[keyName]))
}

console.warn = () => {};
var setMain = function (routes) {
  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  })
  router.beforeEach((to, from, next) => {
    if (to.path != '/login') {
      if (!!sessionStorage.getItem('access_token')) {
        next();
      } else {
        sessionStorage.setItem('access_token', '')
        store.commit('setAccessToken', '')
        next('/login');
      }
    } else {
      next();
    }
  })
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
}
export default setMain


