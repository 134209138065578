const mutation = {
    setAccessToken(state,key) {
        sessionStorage.setItem('access_token', key)
        state.access_token = key;
    },
    setSearchable(state,val){
        state.searchable = val;
    },
    setDlShow(state,val){
        state.dlShow = val||false;
    },
    setDlShow(state,val){
        state.dlShow = val||false;
    },
    setConfigObj(state,val){
        let configs={
            '删除':{
                title: "提示",
                slotName: "提示",
                content: "是否确认删除吗？",
                tips: "此操作将不可逆，请您谨慎操作",
                width:"500px",
            },
            '导出':{
                title: "提示",
                slotName: "提示",
                content: "是否导出当前列表用户数据？",
                tips: "",
                width:"500px",
            },
            '保存字段':{
                title: "提示",
                slotName: "提示",
                content: "是否确认保存？",
                tips: "",
                width:"500px",
            },
            '新增厂商':{
                title: "新增厂商",
                slotName: "厂商",
                width:"500px",
                disabled:false,
            },
            '修改厂商':{
                title: "新增厂商",
                slotName: "厂商",
                width:"500px",
                disabled:false,
            },
            '查看厂商':{
                title: "新增厂商",
                slotName: "厂商",
                width:"500px",
                disabled:true,
            },
            '解绑所属机构':{
                title: "切换所属机构",
                slotName: "解绑所属机构",
                width:"500px",
                disabled:true,
            },
            '选择所属机构':{
                title: "选择所属机构",
                slotName: "选择所属机构",
                width:"500px",
                disabled:true,
            },
            '批量新增':{
                title: "批量新增",
                slotName: "批量新增",
                width:"500px",
            },
            '编辑设备':{
                title: "新增设备",
                slotName: "编辑设备",
                width:"500px",
            },
        }
        state.configObj ={}
        if(typeof val =='string'){
            state.configObj = configs[val]
            state.configObj.name=val
        }else{
            state.configObj=Object.assign(val.name?configs[val.name]:{},val)
            state.configObj.name=val.name
        }
     
    },
    setDlFormObj(state,val){
        state.dlFormObj = JSON.parse(JSON.stringify(val||{}))
    },
    setUserInfo(state,val){
        sessionStorage.setItem('userInfo', val||{})
        state.userInfo = val||{}
    },
    setHome(state,val){
        let value=JSON.parse(JSON.stringify(val))
        sessionStorage.setItem('isHome', value)
        state.isHome = value
    }, 
    setPageSize(state,val){
        state.pageSize = val
    },
    setSystemConfig(state,val){
        let value=JSON.stringify(val)
        sessionStorage.setItem('systemConfig', value)
        state.systemConfig = JSON.parse(value)
    },
}
export default mutation